import { FormInput } from '@dx-ui/osc-form';
import { useTranslation } from 'next-i18next';
import type { Hhonors } from './utils';
import { isOwner, isTeamMember, isFriendsAndFamily, isHGVMax, isSmbMember } from './utils';
import { useFormContext } from 'react-hook-form';
import { useSMBContextLocalStorage } from '@dx-ui/utilities-use-smb-context-local-storage';

export type SpecialRates = {
  aaaRate: boolean;
  aarpRate?: boolean;
  corporateCode: string;
  employeeRate: boolean;
  friendsAndFamilyRate: boolean;
  governmentOrMilitaryRate: boolean;
  groupCode: string;
  offerId: null | { id?: number; name?: string };
  ownerHGVRate: boolean;
  ownerVIPRate: boolean;
  pnd: null | { pnd?: string; name?: string };
  promoCode: string;
  redeemPts: boolean;
  requestedRatesOnly: boolean;
  searchedRatePlanDescription: string;
  seniorRate: boolean;
  smbRate: boolean;
  travelAgentId: string;
  travelAgentRate: boolean;
  useOfferId: boolean;
  usePnd: boolean;
  useRequestedRatesOnly: boolean;
};

export type SpecialRatesKey = keyof SpecialRates;

export const specialRatesKeys: Array<SpecialRatesKey> = [
  'aaaRate',
  'aarpRate',
  'corporateCode',
  'employeeRate',
  'friendsAndFamilyRate',
  'governmentOrMilitaryRate',
  'groupCode',
  'offerId',
  'ownerHGVRate',
  'ownerVIPRate',
  'pnd',
  'promoCode',
  'redeemPts',
  'requestedRatesOnly',
  'searchedRatePlanDescription',
  'seniorRate',
  'smbRate',
  'travelAgentId',
  'travelAgentRate',
  'useOfferId',
  'usePnd',
  'useRequestedRatesOnly',
] as const;

export function isSpecialRatesKey(someString: string): someString is keyof SpecialRates {
  return Boolean(specialRatesKeys.find((value) => someString === value));
}

export type SpecialRatesFormContext = { specialRates: SpecialRates; hhonors: Hhonors };

export const SpecialRates = ({
  shouldDisablePoints = false,
  shouldEnableSMBContextAwareRates = false,
  shouldDisableAARP = false,
}: {
  /**  option to disable use points checkbox */
  shouldDisablePoints?: boolean;
  /** Are we checking SMB Context to determine whether to show SMB Rates */
  shouldEnableSMBContextAwareRates?: boolean;
  shouldDisableAARP?: boolean;
}) => {
  const [t] = useTranslation('osc-special-rates');
  const { watch } = useFormContext<SpecialRatesFormContext>();
  const { smbContext } = useSMBContextLocalStorage(shouldEnableSMBContextAwareRates);

  const promoCode = watch('specialRates.promoCode');
  const groupCode = watch('specialRates.groupCode');
  const corporateCode = watch('specialRates.corporateCode');
  const isOfferIdSelected = watch('specialRates.useOfferId');
  const isPndSelected = watch('specialRates.usePnd');
  const smbRate = watch('specialRates.smbRate');
  const hhonors = watch('hhonors');
  const offerId = watch('specialRates.offerId');
  const pnd = watch('specialRates.pnd');
  const searchedRatePlanDescription = watch('specialRates.searchedRatePlanDescription');
  const requestedRatesOnly = watch('specialRates.requestedRatesOnly');
  const requestedRatesOnlySelected = watch('specialRates.useRequestedRatesOnly');
  const isOnBusinessProfile = smbContext === 'business';
  const isSMBRatesEnabled = shouldEnableSMBContextAwareRates ? isOnBusinessProfile : true;

  const oneCode =
    [
      !!promoCode,
      !!groupCode,
      !!corporateCode,
      !!isPndSelected,
      !!isOfferIdSelected,
      !!requestedRatesOnlySelected,
    ].filter(Boolean).length > 0;

  const showSMBCheckbox = isSmbMember(hhonors) && isSMBRatesEnabled;
  const isCorpFieldDisabled =
    !!isPndSelected ||
    ((oneCode || !!smbRate) && !corporateCode) ||
    (shouldEnableSMBContextAwareRates && isOnBusinessProfile);

  return (
    <div className="w-full space-y-4" data-testid="special-rates">
      <div
        data-osc-product="search-rates-first"
        className="grid h-auto gap-4 md:grid-cols-1 lg:grid-cols-2"
      >
        <FormInput
          disabled={shouldDisablePoints}
          type="checkbox"
          label={t('usePoints')}
          name="specialRates.redeemPts"
        />
        <FormInput type="checkbox" label={t('travelAgents')} name="specialRates.travelAgentRate" />
        <FormInput type="checkbox" label={t('aaaRate')} name="specialRates.aaaRate" />
        {shouldDisableAARP ? null : (
          <FormInput type="checkbox" label={t('aarpRate')} name="specialRates.aarpRate" />
        )}
        <FormInput type="checkbox" label={t('seniorRate')} name="specialRates.seniorRate" />
        <FormInput
          type="checkbox"
          label={t('gvmtRates')}
          name="specialRates.governmentOrMilitaryRate"
        />
        {(isOwner(hhonors) || isTeamMember(hhonors)) && (
          <FormInput type="checkbox" label={t('employeeRate')} name="specialRates.employeeRate" />
        )}
        {(isOwner(hhonors) || isTeamMember(hhonors) || isFriendsAndFamily(hhonors)) && (
          <FormInput
            type="checkbox"
            label={t('friendsAndFamilyRate')}
            name="specialRates.friendsAndFamilyRate"
          />
        )}
        {isOwner(hhonors) && (
          <FormInput type="checkbox" label={t('ownerVIPRate')} name="specialRates.ownerVIPRate" />
        )}
        {isHGVMax(hhonors) && (
          <FormInput type="checkbox" label={t('hgvMaxRate')} name="specialRates.ownerHGVRate" />
        )}
        {showSMBCheckbox ? (
          <label className="label">
            <FormInput type="checkbox" label={t('smbRate')} name="specialRates.smbRate" />
          </label>
        ) : null}
      </div>
      <div className="space-y-2">
        {offerId?.name || pnd?.name || requestedRatesOnly ? (
          <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2">
            {offerId?.name || requestedRatesOnly ? (
              <label className="label">
                <span className="rtl:pr-1">{t('promoCode')}</span>
                <FormInput
                  type="checkbox"
                  label={
                    searchedRatePlanDescription
                      ? searchedRatePlanDescription
                      : requestedRatesOnly
                      ? t('specialOffer')
                      : offerId?.name
                  }
                  name={
                    requestedRatesOnly
                      ? 'specialRates.useRequestedRatesOnly'
                      : 'specialRates.useOfferId'
                  }
                />
              </label>
            ) : null}
            {pnd?.name ? (
              <label className="label">
                <span className="rtl:pr-1">{t('corpAccount')}</span>
                <FormInput type="checkbox" label={pnd?.name} name="specialRates.usePnd" />
              </label>
            ) : null}
          </div>
        ) : null}
        <div
          data-osc-product="search-rates-second"
          className="grid gap-4 md:grid-cols-1 lg:grid-cols-2"
        >
          {!offerId?.name && !requestedRatesOnly ? (
            <FormInput
              label={t('promoCode')}
              labelClassName="w-full"
              name="specialRates.promoCode"
              disabled={!!isOfferIdSelected || (oneCode && !promoCode)}
              maxLength={6}
              registerOptions={{
                pattern: {
                  value: /^[A-Za-z0-9]{2,6}$/,
                  message: t('promoCodeError'),
                },
              }}
            />
          ) : null}
          <FormInput
            label={t('groupCode')}
            labelClassName="w-full"
            name="specialRates.groupCode"
            disabled={oneCode && !groupCode}
            maxLength={6}
            registerOptions={{
              pattern: {
                value: /^[A-Za-z0-9]{2,6}$/,
                message: t('groupCodeError'),
              },
            }}
          />
          <div>
            {!pnd?.name ? (
              <FormInput
                label={t('corpAccount')}
                labelClassName="w-full"
                name="specialRates.corporateCode"
                disabled={isCorpFieldDisabled}
                maxLength={10}
                registerOptions={{
                  pattern: {
                    value: /^[A-Za-z0-9]{2,10}$/,
                    message: t('corpAccountError'),
                  },
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="text-text-alt" aria-live="polite">
        {oneCode && (
          <div className="flex gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
              className="text-text-disabled size-5 shrink-0"
            >
              <circle fill="#FFF" cx="12" cy="12" r="11.5" />
              <circle fill="currentColor" cx="12" cy="12" r="10" />
              <path
                fill="#FFF"
                d="m14 15.25h-1.25v-4.25c0-.41-.34-.75-.75-.75h-2c-.41 0-.75.34-.75.75s.34.75.75.75h1.25v3.5h-1.25c-.41 0-.75.34-.75.75s.34.75.75.75h4c.41 0 .75-.34.75-.75s-.34-.75-.75-.75z"
              />
              <path fill="#FFF" d="m12 9c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1z" />
            </svg>{' '}
            {t('oneCodeError')}
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecialRates;
