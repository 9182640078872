import { getFormattedTime } from '@dx-ui/framework-i18n';
import type { HotelPlaceHours } from '@dx-ui/gql-types';
import type {
  GetHotelDiningPageQuery,
  GetHotelInfoPageQuery,
  GetHotelSpaPageQuery,
  Hotel_Restaurant_Hours_Operation_FragmentFragment,
} from '@dx-ui/queries-dx-property-ui';

export type SpaOperatingHours = NonNullable<
  NonNullable<GetHotelSpaPageQuery['hotel']>['spa']
>['operatingHours'];

type Day = {
  index: number;
  day: string;
};

export type ConsolidatedTime = {
  time: string[];
  days: Day[];
  lowestIndex: number;
};

type DayName = keyof NonNullable<
  NonNullable<
    NonNullable<GetHotelDiningPageQuery['hotel']>['restaurantOverview']
  >['restaurants'][number]['hoursOfOperation']
>;

const format12HourTime = (timeStr: string) => {
  const [rawHours, rawMinutes] = timeStr.split(':') as [string, string];
  const hoursToNum = Number(rawHours);
  const amOrPm = rawMinutes.includes('am') ? 'am' : 'pm';
  const formattedHours = amOrPm === 'pm' && hoursToNum !== 12 ? hoursToNum + 12 : hoursToNum;
  return formattedHours;
};

const compareArrays = (a: string[], b: string[]) =>
  a?.length === b?.length && a.every((element, index) => element === b[index]);

const consolidateOpenCloseToStringFormatter = (time: string) =>
  time.toLowerCase().replace(/(^|-)0/g, '');

const consolidateOpenCloseToString = (open: string, close: string): string =>
  `${consolidateOpenCloseToStringFormatter(open)} - ${consolidateOpenCloseToStringFormatter(
    close
  )}`;

type RestaurantHoursOfOperation = NonNullable<
  NonNullable<
    NonNullable<GetHotelDiningPageQuery['hotel']>['restaurantOverview']
  >['restaurants'][number]['hoursOfOperation']
>;
type RestaurantHoursOfOperationDay = RestaurantHoursOfOperation[DayName];
type RestaurantHoursOfOperationDayHours = RestaurantHoursOfOperationDay[number];

export const getConsolidatedHoursOfOperation = ({
  hoursOfOperation,
  locale,
}: {
  hoursOfOperation: RestaurantHoursOfOperation | null | undefined;
  locale: string;
}): ConsolidatedTime[] => {
  if (hoursOfOperation) {
    const reshapedHours = (Object.keys(hoursOfOperation) as DayName[]).reduce(
      (accumulatedDays: ConsolidatedTime[], currentDay: DayName): ConsolidatedTime[] => {
        const dayOfTheWeekOpenCloseTimesFormatted = hoursOfOperation[currentDay]
          .sort(
            (
              hoursObjOne: RestaurantHoursOfOperationDayHours,
              hoursObjTwo: RestaurantHoursOfOperationDayHours
            ) => {
              const objOneFormattedHours = format12HourTime(hoursObjOne.open.toLowerCase());
              const objTwoFormattedHours = format12HourTime(hoursObjTwo.open.toLowerCase());
              if (objOneFormattedHours < objTwoFormattedHours) {
                return -1;
              } else if (objOneFormattedHours > objTwoFormattedHours) {
                return 1;
              }
              return 0;
            }
          )
          .map((hours) => {
            const localizedOpenHours = getFormattedTime(hours.open, locale);
            const localizedCloseHours = getFormattedTime(hours.close, locale);
            return consolidateOpenCloseToString(localizedOpenHours, localizedCloseHours);
          });

        const currentDayReshaped: ConsolidatedTime = {
          days: [{ day: currentDay, index: 0 }],
          time: dayOfTheWeekOpenCloseTimesFormatted,
          lowestIndex: accumulatedDays.length,
        };

        if (accumulatedDays?.length) {
          const previousAccumulatedDayObj = accumulatedDays[accumulatedDays.length - 1];

          const isPreviousAccumulatedTimeEqualCurrentDayTime = compareArrays(
            previousAccumulatedDayObj?.time || [],
            dayOfTheWeekOpenCloseTimesFormatted
          );

          if (isPreviousAccumulatedTimeEqualCurrentDayTime) {
            const accumulatedDaysMinusLast = accumulatedDays.slice(0, accumulatedDays.length - 1);
            const accumulatedLastDay = accumulatedDays.at(-1) || ({} as ConsolidatedTime);
            const consolidatedMatchingHours: ConsolidatedTime = {
              ...accumulatedLastDay,
              days: [
                ...accumulatedLastDay.days,
                { day: currentDay, index: accumulatedLastDay.days.length },
              ],
            };

            return [...accumulatedDaysMinusLast, consolidatedMatchingHours];
          }
        }

        return [...accumulatedDays, currentDayReshaped];
      },
      [] as ConsolidatedTime[]
    );
    return reshapedHours;
  }

  return [];
};

type PoolHoursOfOperation = NonNullable<
  NonNullable<
    NonNullable<GetHotelInfoPageQuery['hotel']>['pools']
  >['poolDetails'][number]['hoursOfOperation']
>;
type PoolHoursOfOperationDay = PoolHoursOfOperation[DayName];
type PoolHoursOfOperationDayHours = NonNullable<PoolHoursOfOperationDay>['hoursOfOperation'];
type NormalizedPoolHoursOfOperationDay = {
  [key in DayName]: PoolHoursOfOperationDayHours;
};

export const getConsolidatedHoursOfOperationPool = ({
  hoursOfOperation,
  locale,
}: {
  hoursOfOperation: PoolHoursOfOperation;
  locale: string;
}): ConsolidatedTime[] => {
  if (!hoursOfOperation) {
    return [];
  }
  const result = {} as NormalizedPoolHoursOfOperationDay;
  (Object.keys(hoursOfOperation) as DayName[]).forEach((dayName: DayName) => {
    if (result) {
      result[dayName] = hoursOfOperation[dayName]?.hoursOfOperation ?? [];
      result[dayName] = result[dayName].map((hours: { open: string; close: string }) => {
        return {
          open: hours.open,
          close: hours.close,
        };
      });
    }
  });
  return result ? getConsolidatedHoursOfOperation({ hoursOfOperation: result, locale }) : [];
};

export const getConsolidatedHoursOfOperationRestaurant = ({
  hoursOfOperation,
  locale,
}: {
  hoursOfOperation: RestaurantHoursOfOperation | null | undefined;
  locale: string;
}): ConsolidatedTime[] => {
  return hoursOfOperation ? getConsolidatedHoursOfOperation({ hoursOfOperation, locale }) : [];
};

export const getConsolidatedHoursOfOperationSpa = (operatingHours?: SpaOperatingHours) => {
  return operatingHours?.reduce((acc, operatingHoursWeek) => {
    const days = (Object.keys(operatingHoursWeek) as (keyof typeof operatingHoursWeek)[]).filter(
      (day) => day !== 'headline'
    );
    days.forEach((day) => {
      const dayHours = operatingHoursWeek?.[day];
      if (dayHours[0]) {
        const times = dayHours[0].split(' - ');
        const hours = {
          open: times[0] || '',
          close: times[1] || '',
        };
        if (acc[day]) {
          acc[day].push(hours);
        } else {
          acc[day] = [hours];
        }
      } else {
        if (!acc[day]) {
          acc[day] = [];
        }
      }
    });
    return acc;
  }, {} as NonNullable<Hotel_Restaurant_Hours_Operation_FragmentFragment['hoursOfOperation']>);
};

export const getFormattedHours = (operatingHours: HotelPlaceHours[]) => {
  const hours: string[] = [];
  const days = (
    Object.keys(operatingHours[0] || {}) as (keyof (typeof operatingHours)[0])[]
  ).filter((day) => day !== 'headline');

  const convertTime = (timeStr: string) => {
    const [time, modifier] = timeStr.split(' ') as [string, string];
    const hoursMinutes = time.split(':');
    let hours: string | number = hoursMinutes[0] || '';
    const minutes = hoursMinutes[1];

    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };

  days.forEach((day) => {
    const dayHours = operatingHours?.[0]?.[day];
    if (Array.isArray(dayHours) && dayHours[0]) {
      const times = dayHours[0].split(' - ');
      const isClosed = times[0] === 'Closed';
      const formattedHours =
        !isClosed && times[0] && times[1]
          ? `${convertTime(times[0])} - ${convertTime(times[1])}`
          : 'Closed';

      switch (day) {
        case 'monday':
          hours.push(`Mo ${formattedHours}`);
          break;
        case 'tuesday':
          hours.push(`Tu ${formattedHours}`);
          break;
        case 'wednesday':
          hours.push(`We ${formattedHours}`);
          break;
        case 'thursday':
          hours.push(`Th ${formattedHours}`);
          break;
        case 'friday':
          hours.push(`Fr ${formattedHours}`);
          break;
        case 'saturday':
          hours.push(`Sa ${formattedHours}`);
          break;
        case 'sunday':
          hours.push(`Su ${formattedHours}`);
          break;
        default:
          break;
      }
    }
  });

  return hours;
};
