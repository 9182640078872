import type { NextRouter } from 'next/router';
import { getCtyhocnFromSlug } from './url';

type GetRouteParamsResult = {
  ctyhocn: string;
  hotelSlug: string;
  lang: string;
  wifi: boolean;
};

export const getHotelsRouteParams = (router: NextRouter): GetRouteParamsResult => {
  const IsValidCtyhocnSlug = (slug: string): boolean => {
    const slugAfterCtyhocn = slug.substr(7);
    return slugAfterCtyhocn ? /^-[A-Z0-9-]*$/i.test(slugAfterCtyhocn) : true;
  };

  const GetStringFromQuery = (input: string | string[] = ''): string =>
    input === 'undefined'
      ? ''
      : ((Array.isArray(input) && input.length > 0 ? input[0] : input) as string) || '';

  const lang = GetStringFromQuery(router?.query?.language) || '';
  const hotelSlug = GetStringFromQuery(router?.query?.hotelSlug) || '';
  const ctyhocn = IsValidCtyhocnSlug(hotelSlug) ? getCtyhocnFromSlug(hotelSlug) : '';
  const wifi = router?.query?.wifi === 'true';

  return {
    ctyhocn,
    hotelSlug,
    lang,
    wifi,
  };
};
