import type {
  GalleryImagePrimaryCategory,
  TGalleryImageSet,
  TGalleryTabs,
} from '../../../components/property/gallery-grid/GalleryGrid-types';
import { galleryPrimaryCategoryMapper } from '../../../components/property/gallery-grid/GalleryGrid-types';

const getGalleryCategoryToImageMapping = (
  primaryCategoryName: Exclude<(typeof GalleryImagePrimaryCategory)[number], 'all'>,
  hotelFeaturesData: string[],
  roomFeaturesData: string[]
) => {
  const categoryDataSet = galleryPrimaryCategoryMapper[primaryCategoryName] || {};
  if (Object.keys(categoryDataSet).length > 0) {
    const isHotelFeaturesMapped =
      hotelFeaturesData.length > 0 && categoryDataSet?.hotelFeatures.length > 0
        ? hotelFeaturesData.some((elem) => categoryDataSet.hotelFeatures.includes(elem))
        : false;
    const isRoomFeaturesMapped =
      roomFeaturesData.length > 0 && categoryDataSet?.roomFeatures.length > 0
        ? roomFeaturesData.some((elem) => categoryDataSet.roomFeatures.includes(elem))
        : false;
    return isHotelFeaturesMapped || isRoomFeaturesMapped;
  }
  return false;
};

export const getGalleryGridTabsData = (
  galleryImages: TGalleryImageSet,
  primaryCategories: typeof GalleryImagePrimaryCategory,
  isResortProperty?: boolean
) => {
  const galleryItemsLength = galleryImages?.length || 0;
  if (galleryItemsLength < 12) {
    return null;
  }
  const tabs = galleryImages.reduce((imageGroup, currImg) => {
    const { hotelFeatures_noTx: hotelFeatures, roomFeatures_noTx: roomFeatures } = currImg;
    primaryCategories.forEach((primaryCategoryName) => {
      const arrElem: TGalleryImageSet = imageGroup[primaryCategoryName] ?? [];
      if (primaryCategoryName !== 'all') {
        if (
          (isResortProperty && primaryCategoryName === 'hotel') ||
          (!isResortProperty && primaryCategoryName === 'resort')
        ) {
          return;
        }
        const isCategoryMapped =
          hotelFeatures && roomFeatures
            ? getGalleryCategoryToImageMapping(primaryCategoryName, hotelFeatures, roomFeatures)
            : false;
        isCategoryMapped && arrElem.push(currImg);
      } else {
        arrElem.push(currImg);
      }
      imageGroup[primaryCategoryName] = arrElem;
    });
    return imageGroup;
  }, {} as NonNullable<TGalleryTabs>);

  const tabContent =
    Object.keys(tabs).length > 0
      ? (Object.fromEntries(
          Object.entries(tabs).filter(([_, v]) => Array.isArray(v) && v.length > 0)
        ) as NonNullable<TGalleryTabs>)
      : null;
  const tabCategories =
    tabContent && Object.keys(tabContent).length > 1
      ? (Object.keys(tabContent) as (typeof GalleryImagePrimaryCategory)[number][])
      : null;

  return tabContent && tabCategories ? { tabCategories, tabContent } : null;
};
