import type { TFunction } from 'i18next';
import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';
import { formatDistance } from './distance';

export const getSelfParkingString = (
  t: TFunction<['hotel-policies']>,
  parkingPolicies: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['policy']>['parking']
) => {
  if (parkingPolicies?.hasSelfParking) {
    if (
      (parkingPolicies.selfParkingCharge?.chargeAmount || 0) > 0 ||
      typeof parkingPolicies.selfParkingCharge?.chargeAmount === 'undefined' ||
      parkingPolicies.selfParkingCharge?.chargeAmount === null
    ) {
      if (parkingPolicies.selfParkingCharge?.chargeQuantifier === 'daily') {
        return (
          t('hotel-policies:perDay', {
            chargeAmount: parkingPolicies.selfParkingCharge?.chargeAmountFmt,
          }) || t('hotel-policies:available')
        );
      }
      if (parkingPolicies.selfParkingCharge?.chargeQuantifier === 'hourly') {
        return (
          t('hotel-policies:perHour', {
            chargeAmount: parkingPolicies.selfParkingCharge?.chargeAmountFmt,
          }) || t('hotel-policies:available')
        );
      }
    }
    // else -- selfCharge is zero or undefined
    return t('hotel-policies:complimentary');
  }
  // else -- default case -- hasSelfParking is false
  return t('hotel-policies:notAvailable');
};

export const getEVChargingString = (
  t: TFunction<['hotel-policies']>,
  parkingPolicies: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['policy']>['parking'],
  language: string
) => {
  if (parkingPolicies?.evCharging) {
    if (parkingPolicies.evCharging.hasChargingNearby) {
      return `${t('hotel-policies:nearby')}, ${formatDistance(
        parkingPolicies.evCharging.chargingUnitDistance ?? 0,
        language
      )} ${parkingPolicies.evCharging.chargingUnitDistanceUOM}`;
    }
    if (parkingPolicies.evCharging.hasChargingOnsite) {
      return t('hotel-policies:onSite');
    }
  }
  return t('hotel-policies:notAvailable');
};

export const getValetParkingString = (
  t: TFunction<['hotel-policies']>,
  parkingPolicies: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['policy']>['parking']
) => {
  if (parkingPolicies?.hasValetParking) {
    if (
      (parkingPolicies.valetParkingCharge?.chargeAmount || 0) > 0 ||
      typeof parkingPolicies.valetParkingCharge?.chargeAmount === 'undefined' ||
      parkingPolicies.valetParkingCharge?.chargeAmount === null
    )
      return parkingPolicies.valetParkingCharge?.chargeAmountFmt || t('hotel-policies:available');
    // else -- valetCharge is zero or undefined
    return t('hotel-policies:complimentary');
  }
  // else -- default case -- hasValetParking is false
  return t('hotel-policies:notAvailable');
};

export const getTransformedPetFeeText = (petFeeText: string) => {
  if (petFeeText === '$75(1-4n),$125(5+n)2petsMax,dog/cat only')
    return '1-4 night stay $75; 5+ night stay $125; 2 pets max; dog or cat only';
  if (petFeeText === '$50(1-4n),$75(5+n)2petsMax,dog/cat only')
    return '1-4 night stay $50; 5+ night stay $75; 2 pets max; dog or cat only';
  if (petFeeText === '$50(1-4n),$75(5+n) 2petsMax,dog/cat only')
    return '1-4 night stay $50; 5+ night stay $75; 2 pets max; dog or cat only';
  return petFeeText;
};
