import { useTranslation } from 'next-i18next';
import type * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  ShopFormDates,
  ShopFormGroup,
  ShopFormRooms,
  ShopFormSpecialRates,
  useShopFormContext,
} from '@dx-ui/osc-shop-form';
import { useMonthDateSelectorTest } from '../../hooks/use-month-date-selector-test';
import { SearchMonthDateSelector } from './SearchMonthDateSelector';
import type { ModalType, TSearchWidgetForm } from './search.types';
import { MODAL_SESSION_KEY } from './search.types';
import SearchDatesIndicator from './SearchDatesIndicator';
import { useSelectedDatesIndicatorTest } from '../../hooks/use-selected-dates-indicator-test';

export const SearchWidgetForm = ({
  language,
  searchState,
  onSearchChange,
  initializeSearchState,
  isGroupSearch,
  showNumAttendees,
  ageBasedPricing,
  isAdultsOnly,
  adultAge,
  isPartnerBrand,
  maxOccupants,
  maxNumRooms,
  hideFlexDates,
  minArrivalDate,
  maxArrivalDate,
  currencyCode,
}: TSearchWidgetForm) => {
  const { t } = useTranslation(['osc-rooms', 'dx-search']);
  const { watch } = useShopFormContext();
  const canOnlyBookSingleRoom = maxNumRooms === 1;
  const [isShopFormDateViewLoaded, setShopFormDateViewLoaded] = useState(false);
  const { isMonthTabSelectionVariant: hasMonthDateSelector } =
    useMonthDateSelectorTest(isShopFormDateViewLoaded);
  const { isSelectedDatesIndicatorVariant: loadSelectedDatesIndicator } =
    useSelectedDatesIndicatorTest(isShopFormDateViewLoaded);
  const formState = watch();
  const hasTodayAsDefault = !!(
    searchState?.dates?.arrivalDate && searchState?.dates?.departureDate
  );
  const [activeModal, setActiveModal] = useState<ModalType>(
    () => (window?.sessionStorage?.getItem(MODAL_SESSION_KEY) as ModalType) || null
  );

  const ageRange =
    ageBasedPricing && adultAge
      ? {
          max: adultAge,
          min: 0,
        }
      : undefined;
  const agesRequired = !!ageRange;

  const handleModalOpen = (type: ModalType) => {
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, String(type));
    setActiveModal(type);
    setShopFormDateViewLoaded(true);
  };

  const handleDismiss = () => {
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, '');
    setActiveModal(null);
  };

  const handleConfirmAndClose = () => {
    onSearchChange({ ...formState, displayCurrency: searchState.displayCurrency });
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, '');
    setActiveModal(null);
  };

  const occupancyLimitMessage = canOnlyBookSingleRoom
    ? t('occupancy.occupancySingleRoomLimitMessage')
    : t('occupancy.occupancyLimitMessage');

  const MonthDateSelectorComponent = useCallback(
    ({
      selectedCalendarDate,
      onMonthUpdate,
    }: {
      selectedCalendarDate?: Date;
      onMonthUpdate?: (d: Date) => void;
    }): React.ReactNode => {
      return (
        <SearchMonthDateSelector
          minArrivalDate={minArrivalDate}
          maxArrivalDate={maxArrivalDate}
          locale={language}
          selectedCalendarDate={selectedCalendarDate}
          onMonthUpdate={onMonthUpdate}
        />
      );
    },
    [language, maxArrivalDate, minArrivalDate]
  );

  const shopFormDateProps = {
    flexDatesLabel: t('dx-search:shopByPrice'),
    hasTodayAsDefault,
    language,
    isOpen: activeModal === 'dates',
    onConfirm: () => {
      handleConfirmAndClose();
    },
    onOpen: () => handleModalOpen('dates'),
    onDismiss: handleDismiss,
    hideFlexDates,
    ...(hasMonthDateSelector
      ? {
          MonthDateSelectorComponent,
        }
      : null),
    ...(loadSelectedDatesIndicator ? { SearchDatesIndicator } : null),
  };

  useEffect(() => {
    initializeSearchState({
      ...formState,
      displayCurrency: searchState.displayCurrency || currencyCode,
    });
  }, [currencyCode, formState, initializeSearchState, searchState.displayCurrency]);

  useEffect(() => {
    if (
      searchState?.numAttendees !== formState?.numAttendees ||
      searchState?.numRooms !== formState?.numRooms
    ) {
      onSearchChange(
        { ...formState, displayCurrency: searchState.displayCurrency },
        { skipStateUpdate: true }
      );
    }
  }, [
    formState,
    onSearchChange,
    searchState?.numAttendees,
    searchState?.numRooms,
    searchState?.displayCurrency,
  ]);

  return (
    <>
      <ShopFormDates {...shopFormDateProps} />
      {isGroupSearch ? (
        <ShopFormGroup showNumAttendees={showNumAttendees} />
      ) : (
        <>
          <ShopFormRooms
            occupancyLimitMessage={occupancyLimitMessage}
            ageRange={ageRange}
            open={activeModal === 'rooms'}
            onConfirm={handleConfirmAndClose}
            onOpen={() => handleModalOpen('rooms')}
            onDismiss={handleDismiss}
            agesRequired={agesRequired}
            adultAge={adultAge ?? undefined}
            isAdultsOnly={isAdultsOnly}
            hideGroupLink={isPartnerBrand}
            maxRooms={maxNumRooms}
            maxOccupants={maxOccupants}
          />
          <ShopFormSpecialRates
            open={activeModal === 'specialRates'}
            onConfirm={handleConfirmAndClose}
            onOpen={() => handleModalOpen('specialRates')}
            onDismiss={handleDismiss}
          />
        </>
      )}
    </>
  );
};
