import type { TRoom } from '../../../components/rooms-grid/RoomsGrid';

export const filterToUniqueRoomTypes = (allRooms: TRoom[]) =>
  allRooms.reduce((accumulatedRooms, room) => {
    const duplicateRoomType = accumulatedRooms.find(
      (accumulatedRoom) => accumulatedRoom.roomTypeCode === room.roomTypeCode
    );

    if (duplicateRoomType) {
      return [...accumulatedRooms];
    }

    return [...accumulatedRooms, room];
  }, [] as TRoom[]);

export const isObjectEmpty = (obj: object): boolean => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
};
