import type { HotelTemplateType, Maybe } from '@dx-ui/gql-types';
import type { FormDefaultValues } from '@dx-ui/osc-shop-form';
import type { Child } from '@dx-ui/framework-uri-builder';
import type { GetHotelLayoutQuery } from '@dx-ui/queries-dx-property-ui';
import type { LayoutData } from '../../helpers/utils/server/getLayout.types';
import type {
  TSearchState,
  useSearchContext,
} from '../../helpers/providers/search/search-provider';

export type AdultsAndChildrenRoom = {
  adults?: number;
  children?: Child[];
  roomNumber: number;
};

export type RoomAges = { roomNumber: number; childAges: Child[] };
export const MODAL_SESSION_KEY = 'property_search_widget_modal';
export type ModalType = 'dates' | 'rooms' | 'specialRates' | null;

export type TSearchWidget = {
  ctyhocn: string;
  isGroupSearch: boolean;
  showNumAttendees: boolean;
  ageBasedPricing: LayoutData['ageBasedPricing'];
  isAdultsOnly: boolean;
  adultAge: LayoutData['adultAge'];
  isPartnerBrand: boolean;
  brandCode: string;
  maxOccupants: number;
  maxNumRooms: number;
  hideFlexDates: boolean;
  currencyCode?: string;
  minArrivalDate: string;
  maxArrivalDate: string;
};

export type InitialSearch = Omit<FormDefaultValues, 'rooms'> & {
  ages?: RoomAges[];
  rooms: AdultsAndChildrenRoom[];
};

export type TSearchWidgetProps = {
  adultAge: LayoutData['adultAge'];
  ageBasedPricing: LayoutData['ageBasedPricing'];
  adultsOnly: boolean;
  isPartnerBrand: boolean;
  showNumAttendees?: boolean;
  maxNumRooms: number;
  maxOccupants: number;
  isGroupSearch: boolean;
  hideFlexDates: boolean;
  minArrivalDate: string;
  maxArrivalDate: string;
  currencyCode?: Maybe<string> | undefined;
};

export type TSearchWidgetContentProps = {
  resEnabled: boolean | null | undefined;
  open: Maybe<boolean> | undefined;
  openDate: Maybe<string> | undefined;
  preOpenMsg: Maybe<string> | undefined;
  resEnabledDate: Maybe<string> | undefined;
  hotelName: Maybe<string> | undefined;
  address: NonNullable<NonNullable<GetHotelLayoutQuery['hotelPageTemplate']>['hotel']>['address'];
  isFromWiFi: boolean;
  isNewHotel: boolean;
  traits?: string[];
  templateType: Maybe<HotelTemplateType> | undefined;
  shouldDisplayShopFormOnPage: boolean;
  brandCode: string;
  ctyhocn: string;
  lang: string;
  searchWidgetProps: TSearchWidgetProps;
  isRecentlyRenovated: boolean;
};

export type TSearchWidgetForm = {
  language: string;
  searchState: TSearchState;
  onSearchChange: ReturnType<typeof useSearchContext>['onSearchChange'];
  initializeSearchState: ReturnType<typeof useSearchContext>['initializeSearchState'];
  isGroupSearch: boolean;
  showNumAttendees?: boolean;
  ageBasedPricing: LayoutData['ageBasedPricing'];
  isAdultsOnly: boolean;
  adultAge: LayoutData['adultAge'];
  isPartnerBrand: boolean;
  maxOccupants: number;
  maxNumRooms: number;
  hideFlexDates: boolean;
  minArrivalDate: string;
  maxArrivalDate: string;
  ctyhocn: string;
  currencyCode?: string;
};

export type TSearchDateIndicatorProps = {
  day: Date;
  endDay?: Date;
};
