import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { addDays, isEqual } from 'date-fns';
import Icon from '@dx-ui/osc-icon';
import type { TSearchDateIndicatorProps } from './search.types';
import { formatDateWithAbbreviatedDayAndMonth } from '../../helpers/utils/client/dateAndTime';

const DateIndicatorElem = ({
  dateId,
  highlightDateId,
  displayDate,
}: {
  dateId: 'checkin' | 'checkout';
  highlightDateId: string;
  displayDate: string | undefined;
}) => {
  const { t } = useTranslation('osc-date-picker');
  if (!displayDate) {
    return null;
  }
  return (
    <div
      className="flex flex-col justify-items-start px-2"
      data-testid={`${dateId}-DateIndicatorElem`}
    >
      <span className="text-text-alt self-start text-xs font-medium">{t(dateId)}</span>
      <p
        className={cx('pb-1 text-base font-bold', {
          'border-primary border-b-3': dateId === highlightDateId,
        })}
      >
        {displayDate}
      </p>
    </div>
  );
};

const SearchDatesIndicator = (props: TSearchDateIndicatorProps) => {
  const { day, endDay } = props;
  const [checkInDate, setCheckInDate] = useState(
    day ? formatDateWithAbbreviatedDayAndMonth(day) : undefined
  );
  const [checkOutDate, setCheckOutDate] = useState(
    endDay ? formatDateWithAbbreviatedDayAndMonth(endDay) : undefined
  );
  const [activeDateId, setActiveDateId] = useState<string>(!!day ? 'checkin' : '');

  useEffect(() => {
    if (day && checkInDate) {
      if (!isEqual(day, checkInDate)) {
        setCheckInDate(formatDateWithAbbreviatedDayAndMonth(day));
      }
      if (!endDay) {
        setCheckOutDate(formatDateWithAbbreviatedDayAndMonth(addDays(day, 1)));
        if (activeDateId !== 'checkout') {
          setActiveDateId('checkout');
        }
      }
    }

    if (endDay && checkOutDate && !isEqual(endDay, checkOutDate)) {
      setCheckOutDate(formatDateWithAbbreviatedDayAndMonth(endDay));
      if (activeDateId === 'checkout') {
        setActiveDateId('checkin');
      }
    }
  }, [activeDateId, checkInDate, checkOutDate, day, endDay]);

  return (
    <div className="flex" data-testid="search-dates-indicator-comp">
      <DateIndicatorElem
        dateId="checkin"
        highlightDateId={activeDateId}
        displayDate={checkInDate}
      />
      <div className="flex items-end pb-2">
        <Icon name="arrow-right" className="size-6 rtl:rotate-180" />
      </div>
      <DateIndicatorElem
        dateId="checkout"
        highlightDateId={activeDateId}
        displayDate={checkOutDate}
      />
    </div>
  );
};

export default SearchDatesIndicator;
