import cx from 'classnames';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRect } from '@dx-ui/utilities-use-rect';
import { useIsClient, useMediaQuery } from 'usehooks-ts';
import SearchWidgetContent from './SearchWidgetContentContainer';
import { mapCustomClassesToComponent } from '../../helpers/themes/customTheme';
import { useGlobalConfig } from '../../hooks/use-global-config';
import type { TSearchWidgetContentProps } from './search.types';
import { useStickyLayout } from '@dx-ui/utilities-use-sticky-layout';

export const SearchWidgetBaseContainer: FC<TSearchWidgetContentProps> = (
  searchWidgetContentProps: TSearchWidgetContentProps
) => {
  const { isTailored } = useGlobalConfig();
  const isClient = useIsClient();
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');
  const shopFormRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const shopFormRect = useRect({ ref: shopFormRef });
  const containerRect = useRect({ ref: containerRef });
  const scrollHeightRef = useRef<number>(0);
  const { brandCode } = searchWidgetContentProps;
  const wrapperClass = mapCustomClassesToComponent('navShopForm', brandCode, isTailored);
  const [isSearchWrapperSticky, setSearchWrapperSticky] = useState(false);
  const { isWrapperSticky } = useStickyLayout({
    ref: isLargeScreen ? containerRef : shopFormRef,
  });

  const stickyTopOffset = useMemo(() => {
    if (isLargeScreen || !containerRect || !shopFormRect) {
      return '0px';
    }
    if (isSearchWrapperSticky) {
      const searchWrapperContainerHeight = containerRect?.height;
      const shopFormContainerHeight = shopFormRect?.height;
      const containerHeightDiff = searchWrapperContainerHeight - shopFormContainerHeight;
      const topOffset = containerHeightDiff ? `${-1 * containerHeightDiff}px` : `0px`;
      return topOffset;
    } else {
      return '0px';
    }
  }, [containerRect, isLargeScreen, isSearchWrapperSticky, shopFormRect]);

  const handleScrolling = useCallback(() => {
    if (isLargeScreen || !shopFormRef?.current) return false;
    const childTopOffset = shopFormRef.current.getBoundingClientRect().top;
    if (window.scrollY > scrollHeightRef.current) {
      if (
        window.scrollY > childTopOffset &&
        childTopOffset <= 0 &&
        isWrapperSticky &&
        !isSearchWrapperSticky
      ) {
        shopFormRef.current.classList.remove('py-6');
        shopFormRef.current.classList.add('py-2');
        setSearchWrapperSticky(true);
        return;
      }
    }
    if (window.scrollY < scrollHeightRef.current) {
      if (window.scrollY <= childTopOffset && childTopOffset > 0 && isSearchWrapperSticky) {
        setSearchWrapperSticky(false);
        shopFormRef.current.classList.remove('py-2');
        shopFormRef.current.classList.add('py-6');
        return;
      }
    }
    scrollHeightRef.current = window.scrollY;
  }, [isLargeScreen, isSearchWrapperSticky, isWrapperSticky]);

  useEffect(() => {
    if (isClient) {
      if (!isSearchWrapperSticky || !containerRect?.height || !shopFormRect?.height) {
        return;
      }
      const breathing_room = 20;
      const scrollHeight =
        (isLargeScreen ? containerRect?.height : shopFormRect?.height) + breathing_room;
      document.getElementsByTagName('html')[0].style.scrollPaddingBlockStart = `${scrollHeight}px`;
    } else {
      document.getElementsByTagName('html')[0].style.scrollPaddingBlockStart = `0px`;
    }
  }, [containerRect?.height, isClient, isLargeScreen, isSearchWrapperSticky, shopFormRect?.height]);

  useEffect(() => {
    scrollHeightRef.current = document.scrollingElement?.scrollHeight ?? 0;
    window.addEventListener('scroll', handleScrolling);
    return () => {
      window.removeEventListener('scroll', handleScrolling);
    };
  }, [handleScrolling, isLargeScreen, isSearchWrapperSticky, isWrapperSticky]);

  useEffect(() => {
    if (isLargeScreen) {
      if (isWrapperSticky && !isSearchWrapperSticky) {
        setSearchWrapperSticky(true);
        return;
      }
      if (!isWrapperSticky && isSearchWrapperSticky) {
        setSearchWrapperSticky(false);
        return;
      }
    }
  }, [isLargeScreen, isSearchWrapperSticky, isWrapperSticky]);

  return (
    <div
      ref={containerRef}
      className={cx(
        'bg-bg border-border-alt inset-0 m-0 w-full flex-col flex-nowrap border-b border-solid pb-0 pt-6 transition-[padding] duration-[0.6s] ease-in-out motion-reduce:transition-none lg:pb-6 lg:transition-none',
        {
          'z-30 sticky': isSearchWrapperSticky,
          'z-0': !isSearchWrapperSticky,
        },
        wrapperClass
      )}
      style={{ top: stickyTopOffset }}
    >
      <SearchWidgetContent {...searchWidgetContentProps} ref={shopFormRef} />
    </div>
  );
};
