import type { CarouselImages } from '@dx-ui/osc-carousel';
import type { DiningImage } from '../../../components/cta-cards/cta-card';
import find from 'lodash/find';
import type * as Types from '@dx-ui/gql-types';
import { HotelImageVariantSize } from '../../constants';
import type {
  GetHotelEventsPageQuery,
  GetHotelHomePageQuery,
  GetHotelRoomsQuery,
} from '@dx-ui/queries-dx-property-ui';

function determineVariant(
  width: number | undefined = 0,
  enumTS: typeof HotelImageVariantSize,
  mappedVariantUrlQueryParams: TVariantImage
) {
  const { xs, sm, md } = mappedVariantUrlQueryParams;
  const small = parseInt(xs);
  const medium = parseInt(sm);
  const large = parseInt(md);

  if (small && width <= small) {
    return enumTS.Xs;
  }
  if (medium && width <= medium) {
    return enumTS.Sm;
  }
  if (large) {
    return enumTS.Md;
  }

  return undefined;
}

export const getHotelImageVariant = (
  variants: Array<Pick<Types.HotelImageVariant, 'size' | 'url'>>,
  width: number | undefined
) => {
  const mappedVariantUrlQueryParams = variants?.reduce(
    (acc: TVariantImage, { size, url }: Pick<Types.HotelImageVariant, 'size' | 'url'>) => {
      if (url) {
        const urlMap = url?.split('?')[1]?.split('&');
        const rwQueryParamValue =
          Array.isArray(urlMap) && urlMap.length > 0
            ? urlMap.find((elem) => elem.includes('rw='))?.split('=')[1]
            : '';
        if (size && rwQueryParamValue) {
          acc[size] = rwQueryParamValue;
        }
      }
      return acc;
    },
    {
      xs: '',
      sm: '',
      md: '',
    }
  );
  const selectedImageVariantData = find(variants, {
    size: determineVariant(width, HotelImageVariantSize, mappedVariantUrlQueryParams),
  });
  return selectedImageVariantData;
};

export const structuredCarouselImages = (
  images: DiningImage,
  width: number | undefined
): CarouselImages[] | [] => {
  if (!images.length) return [];

  return images
    .map((image) => {
      if (image?.variants.length) {
        const url = getHotelImageVariant(image.variants, width)?.url;
        if (url) {
          return {
            url,
            alt: image?.altText,
          };
        }
      }
      return null;
    })
    .filter(Boolean) as CarouselImages[];
};

type TVariantImage = {
  xs: string;
  md: string;
  sm: string;
};

export const searchEngineOptimizedImages = (
  imageUrls: Array<{ url?: string | null }>
): string[] | [] => {
  if (!imageUrls.length || !imageUrls[0]?.url) return [];

  const sixteenByNineRatio =
    '?impolicy=crop&cw=5000&ch=2812&gravity=NorthWest&xposition=0&yposition=0&rw=1200&rh=675';
  const fourByThreeRatio =
    '?impolicy=crop&cw=3750&ch=2812&gravity=NorthWest&xposition=625&yposition=0&rw=1200&rh=900';

  return imageUrls
    .map((imageObj) => [
      `${imageObj.url}${sixteenByNineRatio}`,
      `${imageObj.url}${fourByThreeRatio}`,
    ])
    .flat();
};

export const getSingleCarouselImages = (
  carouselImages:
    | NonNullable<GetHotelRoomsQuery['hotel']>['roomTypes'][number]['carousel']
    | NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['images']>['carousel']
): CarouselImages[] =>
  carouselImages.map((carouselImage) => ({
    alt: carouselImage.altText || '',
    url: carouselImage.variants.find((variant) => variant.size === 'sm')?.url || '',
  }));

export const getEventsCarouselImages = (
  carouselImages: NonNullable<
    NonNullable<GetHotelEventsPageQuery['hotel']>['images']
  >['meetingGallery']
): CarouselImages[] =>
  carouselImages.map((carouselImage) => ({
    alt: carouselImage.image?.altText || '',
    url: carouselImage.image?.variants.find((variant) => variant.size === 'md')?.url || '',
  }));
