export const transformTrackingId = (id?: string) => {
  if (!id) {
    return '';
  }

  const transformed_id = id
    .replace(/\-+/g, ' ')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s+/g, '_')
    .replace(/\_+/g, '_')
    .toLowerCase();

  return transformed_id;
};
