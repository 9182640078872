import { getIntlNumberLocale } from '@dx-ui/framework-i18n';
import type * as Types from '@dx-ui/gql-types';
import type { TFunction } from 'i18next';

export const formatDistance = (distance: number, language: string) => {
  const digits = distance % 1 === 0 ? 0 : 2;
  return new Intl.NumberFormat(getIntlNumberLocale(language), {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(distance);
};

export const getDistance = ({
  t,
  distance,
  distanceUnit,
  language,
}: {
  t: TFunction<['hotel-location']>;
  distance?: number | null;
  distanceUnit: Types.HotelDistanceUnit | null | undefined;
  language: string;
}) =>
  distanceUnit
    ? t(`hotel-location:units.${distanceUnit}`, {
        count: distance ?? 0,
        distance: formatDistance(distance ?? 0, language),
      })
    : '';
