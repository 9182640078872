import type { FC, KeyboardEvent } from 'react';
import { useState, useMemo, useEffect } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import cx from 'classnames';
import { startOfMonth, isEqual, parseISO } from 'date-fns';
import { sendReward } from '@dx-ui/framework-conductrics';
import { getMonthRangeBetweenDates } from '../../helpers/utils/client';
import { transformTrackingId } from '../../helpers/metrics/transformTrackingId';

type TSearchMonthDateSelectorProps = {
  locale: string;
  minArrivalDate: string;
  maxArrivalDate: string;
  selectedCalendarDate?: Date;
  onMonthUpdate?: (d: Date) => void;
};

export const SearchMonthDateSelector: FC<TSearchMonthDateSelectorProps> = ({
  locale,
  minArrivalDate,
  maxArrivalDate,
  selectedCalendarDate,
  onMonthUpdate,
}) => {
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');
  const monthTabRefs: HTMLButtonElement[] = [];
  const formattedMinArrivalDate = minArrivalDate && parseISO(minArrivalDate);
  const [focussedMonthTab, setFocussedMonthTab] = useState(
    (formattedMinArrivalDate && startOfMonth(formattedMinArrivalDate)) || startOfMonth(new Date())
  );
  const [userSelectedDate, setUserSelectedDate] = useState(formattedMinArrivalDate || new Date());
  const [tabAutoFocus, setTabAutoFocus] = useState(false);
  const [isFirstMonthSelected, setFirstMonthSelected] = useState(false);
  const monthsRange = getMonthRangeBetweenDates(minArrivalDate, maxArrivalDate);

  const onKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    switch (e.key) {
      case 'End':
        e.preventDefault();
        monthTabRefs[monthTabRefs.length - 1]?.focus();
        break;
      case 'Home':
        e.preventDefault();
        monthTabRefs[0]?.focus();
        break;
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLButtonElement>, index: number) => {
    let tabRef = 0;
    switch (e.key) {
      case 'ArrowLeft':
        tabRef = index - 1 >= 0 ? index - 1 : monthTabRefs.length - 1;
        monthTabRefs[tabRef]?.focus();
        break;
      case 'ArrowRight':
        tabRef = index + 1 <= monthTabRefs.length - 1 ? index + 1 : 0;
        monthTabRefs[tabRef]?.focus();
        break;
      case 'Enter':
      case 'Space':
        break;
    }
  };

  useEffect(() => {
    if (selectedCalendarDate) {
      const currentSelectionDateMonth = startOfMonth(selectedCalendarDate);
      const prevSelectionDateMonth = startOfMonth(userSelectedDate);
      if (!isEqual(currentSelectionDateMonth, prevSelectionDateMonth)) {
        setFocussedMonthTab(currentSelectionDateMonth);
        !tabAutoFocus && setTabAutoFocus(true);
        setUserSelectedDate(selectedCalendarDate);
      }
    }
  }, [selectedCalendarDate, tabAutoFocus, userSelectedDate]);

  const monthsData = useMemo(() => {
    if (!monthsRange) {
      return [];
    }
    return monthsRange?.map((monthStartDate) => {
      const isSelected = isEqual(monthStartDate, focussedMonthTab);
      const monthYear = monthStartDate.toLocaleDateString(locale, {
        month: 'short',
        year: 'numeric',
      });
      const unabbreviatedMonthYear = monthStartDate.toLocaleDateString(locale, {
        month: 'long',
        year: 'numeric',
      });
      return {
        monthStartDate,
        isSelected,
        monthYear,
        unabbreviatedMonthYear,
      };
    });
  }, [locale, monthsRange, focussedMonthTab]);

  const sendConductricsRewards = () => {
    if (!isFirstMonthSelected) {
      sendReward('g-month-tab-engagement');
      setFirstMonthSelected(true);
    }
    sendReward('g-month-tab-engagement-total');
  };

  return (
    <div
      className="scrollbar-hide m-1 flex snap-x snap-mandatory scroll-ps-8 overflow-x-scroll px-1 sm:mx-2"
      role="tablist"
      data-testid="search-month-tab-selector"
    >
      {monthsData.map(
        ({ monthStartDate, monthYear, isSelected, unabbreviatedMonthYear }, index) => (
          <button
            className={cx(
              'my-1 flex snap-start flex-col items-center px-8 py-2 text-center md:px-5',
              {
                'border-primary border-b-2': isSelected,
              }
            )}
            id={transformTrackingId(`tab-${index + 1}-${monthYear}`)}
            key={monthYear}
            tabIndex={!isSelected ? -1 : 0}
            ref={(el) => {
              if (!el) {
                return;
              }
              if (isSelected) {
                el.scrollIntoView({
                  inline: isLargeScreen ? 'center' : 'start',
                  behavior: 'smooth',
                  block: 'nearest',
                });
                if (tabAutoFocus) {
                  el.focus();
                  setTabAutoFocus(false);
                }
              }
              monthTabRefs.push(el);
            }}
            onClick={() => {
              onMonthUpdate?.(monthStartDate);
              setFocussedMonthTab(monthStartDate);
              setTabAutoFocus(true);
              sendConductricsRewards();
            }}
            onKeyDown={onKeyDown}
            onKeyUp={(e) => onKeyUp(e, index)}
            aria-selected={isSelected}
            aria-label={unabbreviatedMonthYear}
            role="tab"
            type="button"
          >
            <span
              className={cx('text-xs font-bold', {
                'text-primary': isSelected,
                'text-text-alt': !isSelected,
              })}
            >
              {monthYear}
            </span>
          </button>
        )
      )}
    </div>
  );
};
