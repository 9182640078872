import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';

export const arrayToFormattedString = (arrayOfStrings: string[]): string | null => {
  if (arrayOfStrings.length === 1) {
    return `${arrayOfStrings.join()}.`;
  }

  if (arrayOfStrings.length === 2) {
    return `${arrayOfStrings.join(' and ')}.`;
  }

  if (arrayOfStrings.length > 2) {
    const stringsWithCommas = arrayOfStrings.slice(0, arrayOfStrings.length - 1).join(', ');
    const lastStringWithAnd = arrayOfStrings.slice(-1);

    return `${stringsWithCommas}, and ${lastStringWithAnd}.`;
  }

  return null;
};

export const replacePimStringWithNewString = (
  amenitiesFromPim: NonNullable<GetHotelHomePageQuery['hotel']>['accessibleFeatures'] | undefined,
  replacementText: { newText: string; oldText: string }[]
) =>
  amenitiesFromPim?.map((amenityObj) => {
    const textReplacement = replacementText.find((text) => text.oldText === amenityObj.name);

    if (textReplacement) {
      return { name: textReplacement.newText };
    }

    return amenityObj;
  });
