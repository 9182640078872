import { useState } from 'react';
import cx from 'classnames';
import Image from 'next/image';
import { Caption } from '@dx-ui/osc-caption';
import type { TResponsivePicture } from './responsive-picture.types';
import {
  overrideImageUrl,
  BREAKPOINTS,
  getBreakpoint,
  GetHeightForWidthAndAspectRatio,
  aspectRatioMap,
} from './responsive-image';
import type { AspectRatio } from './responsive-image.types';
import { ElementSelector } from './utils/element-selector';

const getAspectRatioClass = ({
  aspectRatio,
  tabletAspectRatio,
  mobileAspectRatio,
}: {
  aspectRatio: AspectRatio;
  tabletAspectRatio?: AspectRatio;
  mobileAspectRatio?: AspectRatio;
}) => {
  const defaultAspectRatio = 'aspect-[3/2]';

  return [
    mobileAspectRatio
      ? aspectRatioMap[mobileAspectRatio] || defaultAspectRatio
      : defaultAspectRatio,
    tabletAspectRatio ? `sm:${aspectRatioMap[tabletAspectRatio] || defaultAspectRatio}` : '',
    (mobileAspectRatio || tabletAspectRatio) && aspectRatio
      ? `lg:${aspectRatioMap[aspectRatio] || defaultAspectRatio}`
      : aspectRatioMap[aspectRatio] || defaultAspectRatio,
  ]
    .filter(Boolean)
    .join(' ');
};

export const ResponsivePicture = ({
  id,
  altText,
  className = '',
  wrapperClassName,
  wrapperTag,
  captionData,
  bandwidth = 100,
  onImgLoaded,
  priority = false,
  width,
  onClick,
  imageUrl,
  aspectRatio,
  mobileImageUrl,
  mobileAspectRatio,
  tabletImageUrl,
  tabletAspectRatio,
}: TResponsivePicture) => {
  const [loaded, setLoaded] = useState(false);
  const imageWidth = getBreakpoint(width, bandwidth) || 0;

  // Optimized image URLs
  const desktopImageHeight = GetHeightForWidthAndAspectRatio(imageWidth, aspectRatio);
  const desktopResizedImageUrl = imageUrl
    ? overrideImageUrl(imageUrl, desktopImageHeight, imageWidth)
    : '';

  const tabletResizedImageUrl =
    tabletAspectRatio && tabletImageUrl
      ? overrideImageUrl(
          tabletImageUrl,
          GetHeightForWidthAndAspectRatio(imageWidth, tabletAspectRatio),
          imageWidth
        )
      : '';

  const mobileResizedImageUrl =
    mobileAspectRatio && mobileImageUrl
      ? overrideImageUrl(
          mobileImageUrl,
          GetHeightForWidthAndAspectRatio(imageWidth, mobileAspectRatio),
          imageWidth
        )
      : '';

  const captionExists = captionData && Object.keys(captionData).length > 0 && captionData.caption;
  const defaultWrapperTag = captionData ? 'figure' : 'div';

  return (
    <ElementSelector
      data-testid="responsivePictureWrapper"
      figureTag={wrapperTag || defaultWrapperTag}
      className={cx(
        wrapperClassName,
        'relative block w-full overflow-hidden bg-transparent',
        getAspectRatioClass({
          aspectRatio,
          tabletAspectRatio,
          mobileAspectRatio,
        })
      )}
    >
      {desktopResizedImageUrl && (
        <picture data-testid="responsivePicture">
          {mobileImageUrl && (
            <source
              data-testid="mobileSource"
              media={`(max-width: ${BREAKPOINTS.sm}px)`}
              srcSet={mobileResizedImageUrl}
            />
          )}
          {tabletImageUrl && (
            <source
              data-testid="tabletSource"
              media={`(max-width: ${BREAKPOINTS.xl}px)`}
              srcSet={tabletResizedImageUrl}
            />
          )}
          <Image
            id={id}
            className={cx('object-cover', className, {
              'invisible opacity-0': !loaded,
              'opacity-100': loaded,
            })}
            alt={altText}
            src={desktopResizedImageUrl}
            fill
            onLoad={() => {
              setLoaded(true);
              onImgLoaded?.();
            }}
            unoptimized={true}
            priority={priority}
            onClick={onClick}
          />
        </picture>
      )}

      {!loaded && (
        <div
          data-testid="image-loading-widget"
          className="bg-bg-alt absolute inset-0 size-full shrink-0 animate-pulse"
        />
      )}
      {captionExists && <Caption {...captionData} metricsOnClick={onClick} />}
    </ElementSelector>
  );
};

export default ResponsivePicture;
