import { useState } from 'react';
import cx from 'classnames';
import { Image } from 'next/dist/client/image-component';
import { Caption } from '@dx-ui/osc-caption';
import type { AspectRatio, TResponsiveImage } from './responsive-image.types';
import { ElementSelector } from './utils/element-selector';
import { logInfo } from '@dx-ui/framework-logger';

const BREAKPOINTS = {
  xxs: 200,
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
  xxxl: 1920,
  xxxxl: 2560,
  xxxxxl: 3840,
};

function GetHeightForWidthAndAspectRatio(width: number, aspectRatio: AspectRatio) {
  const heightWidthArray = aspectRatio.split(':');
  const denominator = (heightWidthArray?.[0] || 1) as number;
  const multiplier = (heightWidthArray?.[1] || 1) as number;
  const height = (width * multiplier) / denominator;
  return Math.round(height);
}

function getAspectRatio(width: number, height: number, urlString: string) {
  const aspectRatio = width / height;

  if (!Number.isFinite(aspectRatio) || aspectRatio === 0) {
    logInfo(
      'Customer_Experience_ImageError',
      new Error('Invalid aspect ratio'),
      `aspectRatio: ${aspectRatio}, width: ${width}, height: ${height}, imageUrl: "${urlString}"`
    );

    return 3 / 2;
  }

  return aspectRatio;
}

function parseQsNumber(qs: URLSearchParams, key: string, defaultValue: number) {
  const str = qs.get(key) ?? defaultValue.toString();
  const num = Math.abs(Number(str));

  if (!Number.isFinite(num)) {
    return defaultValue;
  }

  return num;
}

/**
 * Takes the image URL and reconstructs it with the appropriate aspect ratio, width and height.
 * Width values are multiplied by 2 to ensure high-res images are served.
 */
function overrideImageUrl(urlString: string, targetHeight: number, targetWidth: number) {
  const queryIndex = urlString.indexOf('?');
  if (queryIndex === -1) {
    return urlString;
  }

  const search = new URLSearchParams(urlString.slice(queryIndex) ?? '');

  const sourceWidth = parseQsNumber(search, 'rw', 0);
  const sourceHeight = parseQsNumber(search, 'rh', 0);
  const sourceAspectRatio = getAspectRatio(sourceWidth, sourceHeight, urlString);

  const targetAspectRatio = getAspectRatio(targetWidth, targetHeight, urlString);

  if (targetAspectRatio > 1) {
    search.set('rh', String(Math.round(targetWidth / sourceAspectRatio) * 2));
    search.set('rw', String(targetWidth * 2));
  } else {
    search.set('rw', String(Math.round(targetHeight * sourceAspectRatio) * 2));
    search.set('rh', String(targetHeight * 2));
  }

  return urlString.slice(0, queryIndex) + '?' + search.toString();
}

/**
 * Takes the component/image width and rounds it to the nearest breakpoint value.
 * Low bandwidth connections return the lowest breakpoint available.
 */
const getBreakpoint = (width: number, bandwidth: number) => {
  const breakpoints = Object.values(BREAKPOINTS);
  const maxBreakpoint = breakpoints[breakpoints.length - 1] ?? 0;
  return width > maxBreakpoint
    ? maxBreakpoint
    : bandwidth > 50
    ? breakpoints.find((breakpoint) => breakpoint >= width)
    : BREAKPOINTS.xxs;
};

/**
 * Responsive Image wraps `next/image` to render an image that responds to tailwind breakpoints.
 */

export const aspectRatioMap: Record<AspectRatio, string> = {
  '3:4': 'aspect-[3/4]',
  '1:1': 'aspect-square',
  '3:2': 'aspect-[3/2]',
  '4:3': 'aspect-[4/3]',
  '4:2': 'aspect-[4/2]',
  '9:16': 'aspect-[9/16]',
  '16:9': 'aspect-[16/9]',
  '21:9': 'aspect-[21/9]',
  '18:5': 'aspect-[18/5]',
  '384:113': 'aspect-[384/113]',
};

export const getAspectRatioClass = (aspectRatio: AspectRatio): string =>
  aspectRatioMap[aspectRatio] || 'aspect-[3/2]';

export const ResponsiveImage = ({
  id,
  altText,
  aspectRatio,
  imageUrl,
  className = '',
  wrapperClassName,
  wrapperTag,
  captionData,
  bandwidth = 100,
  onImgLoaded,
  priority = false,
  width,
  hFull = false,
  maxHeight,
  onClick,
}: TResponsiveImage) => {
  const [loaded, setLoaded] = useState(false);

  const imageWidth = getBreakpoint(width, bandwidth) || 0;

  const imageHeight = GetHeightForWidthAndAspectRatio(imageWidth, aspectRatio);
  const resizedImageUrl = imageUrl ? overrideImageUrl(imageUrl, imageHeight, imageWidth) : '';
  const captionExists =
    typeof captionData === 'object' && Object.keys(captionData).length > 0 && captionData.caption;

  const defaultWrapperTag = captionData ? 'figure' : 'div';

  //TODO updates coming with NHCBP-5517
  return (
    <ElementSelector
      figureTag={wrapperTag || defaultWrapperTag}
      className={cx(
        wrapperClassName,
        `relative block w-full overflow-hidden bg-transparent`,
        {
          'h-full': hFull,
        },
        aspectRatio ? getAspectRatioClass(aspectRatio) : null
      )}
      style={{
        maxHeight: maxHeight ? maxHeight : undefined,
      }}
    >
      {resizedImageUrl && (
        <Image
          id={id}
          className={cx(className, {
            'invisible opacity-0': !loaded, // Added `opacity=0` css to hide the flickering of the loaded image(overlapping loading indicator div) happening due to the `visibility:visible` overriding style from 'next/image' img styles; invisble style is overriden here always
            'opacity-100': loaded,
          })}
          alt={altText}
          src={resizedImageUrl}
          style={{
            objectFit: 'cover',
          }}
          fill
          onLoad={() => {
            setLoaded(true);

            if (onImgLoaded) {
              //TODO tsc is erroniously infering onImgLoaded: never
              onImgLoaded();
            }
          }}
          data-testid="responsiveImageImg"
          unoptimized={true} // required because akamai image manager doesnt support this (will cause 403 otherwise)
          priority={priority}
          onClick={onClick}
        />
      )}

      {!loaded ? (
        <div
          data-testid="image-loading-widget"
          className={cx('bg-bg-alt absolute inset-0 size-full shrink-0 animate-pulse')}
        />
      ) : null}
      {
        /*TODO tsc is erroniously infering captionData: some none object type*/
        captionExists ? <Caption {...captionData} metricsOnClick={onClick} /> : null
      }
    </ElementSelector>
  );
};

export { GetHeightForWidthAndAspectRatio, BREAKPOINTS, overrideImageUrl, getBreakpoint };
export default ResponsiveImage;
