import type { JoinFormMetrics } from '../types/join-form-analytics';

export const sendErrorAnalytics = (
  metrics: JoinFormMetrics,
  formErrors: string[],
  formErrorsMessages: string[]
) => {
  metrics.setPageInfo('alertBoxText', formErrors);
  metrics.trackEvent('alertBoxText');
  metrics.setFormErrors([{ errors: formErrors.join('|') }]);
  metrics.setFormErrorMessages(formErrorsMessages.join('|'));
  metrics.setProperty('page.attributes.actionDetail', 'formError');
  metrics.trackEvent('formErrorEvent');
};
