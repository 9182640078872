import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { Spinner } from '@dx-ui/osc-spinner';

type FormHelpMessage = {
  loading?: boolean;
  message?: string | string[];
} & React.HTMLAttributes<HTMLDivElement>;

const FormHelpMessage = React.forwardRef<HTMLDivElement, FormHelpMessage>(
  ({ className, message, loading, id, ...rest }, ref) => {
    const { t } = useTranslation('osc-form');
    const messages = Array.isArray(message) ? message : [message];

    return (
      <div id={id} className="grid gap-1 pt-1">
        {loading ? (
          <div className="flex gap-2">
            <Spinner size="sm" />
            <p>{t('loading')}</p>
          </div>
        ) : (
          messages &&
          messages?.map((value, index) => (
            <div
              ref={ref}
              className={cx('text-text-alt items-center space-x-2 text-sm', className)}
              key={`help-message-${index + 1}`}
              {...rest}
            >
              <p>{value}</p>
            </div>
          ))
        )}
      </div>
    );
  }
);

FormHelpMessage.displayName = 'FormHelpMessage';

export { FormHelpMessage };
export default FormHelpMessage;
