/* eslint-disable no-shadow */
import type { BrandCode, Locale, BookRates } from '@dx-ui/framework-uri-builder';
import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import { baseUrl } from '../../env-vars';
import type { ShopSpecialRateInput } from '@dx-ui/gql-types';
import type { ShopFormDatesProps } from '@dx-ui/osc-shop-form';
import type { ShopFormRoom } from '@dx-ui/osc-rooms';

export type TDeepLinkProps = {
  // state: SearchFormValue; //need to rename this variable.
  language: string;
  brandCode: string;
  ctyhocn?: string;
  roomTypeCode?: string;
  ratePlanCode?: string;
  rates?: ShopSpecialRateInput;
  dates: ShopFormDatesProps;
  rooms?: ShopFormRoom[];
  page?: NonNullable<Parameters<typeof bookUriBuilder>[0]['page']>;
  additionalQS?: NonNullable<Parameters<typeof bookUriBuilder>[0]['urlParams']>['additionalQS'];
};

export function getBookDeepLink({
  rates,
  language,
  brandCode,
  ctyhocn,
  roomTypeCode,
  ratePlanCode,
  dates,
  rooms,
  page = 'rates',
  additionalQS,
}: TDeepLinkProps) {
  const propertyToBookSpecialRatesMapping: {
    [key in keyof ShopSpecialRateInput]: keyof BookRates;
  } = {
    aaa: 'aaaRate',
    aarp: 'aarpRate',
    corporateId: 'corporateCode',
    familyAndFriends: 'friendsAndFamilyRate',
    governmentMilitary: 'governmentRate',
    groupCode: 'groupCode',
    hhonors: 'redeemPts',
    offerId: 'offerId',
    owner: 'ownerVIPRate',
    ownerHGV: 'ownerHGVRate',
    pnd: 'pnd',
    promoCode: 'promotionCode',
    senior: 'seniorRate',
    smb: 'smbRate',
    specialOffer: 'offerId',
    teamMember: 'employeeRate',
    travelAgent: 'travelAgent',
  };

  const specialRates = rates
    ? (Object.keys(rates) as (keyof typeof rates)[]).reduce((acc, key) => {
        const rateKey = propertyToBookSpecialRatesMapping[key];
        if (rateKey) {
          return { ...acc, [rateKey]: rates[key] };
        }
        return acc;
      }, {} as BookRates)
    : undefined;

  const urlParams = {
    ctyhocn: ctyhocn || '',
    dates,
    rates: specialRates,
    rooms,
    additionalQS: {
      ...additionalQS,
      brandCode: brandCode as keyof BrandCode,
      inputModule: 'HOTEL_SEARCH',
      ...(roomTypeCode && { enhancedCode: roomTypeCode }),
      ...(ratePlanCode && { ratePlanCode }),
    },
  };

  // flexibleDates takes precedence over rates or rooms
  if (urlParams?.dates?.datesFlex && page !== 'payment') {
    page = 'flexibledates';
  }

  const url = bookUriBuilder({
    page,
    locale: language as keyof Locale,
    urlParams,
    baseUrl,
  });

  return url;
}
