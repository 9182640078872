import { eachMonthOfInterval, format, parseISO } from 'date-fns';

export const formatDisplayDateWithYear = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  locale: string
) => {
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    return;
  }

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    calendar: 'gregory',
  }).formatRange(startDate, endDate);
};

export const timeStrToHoursMinutesAmPmObject = (
  timeStr: string
): { hoursMinutes: string; amOrPm: 'am' | 'pm' } => {
  const [rawHours, rawMinutes] = timeStr.split(':');
  const hoursToNum = Number(rawHours);
  const amOrPm = hoursToNum >= 12 ? 'pm' : 'am';
  const hours = ((hoursToNum + 11) % 12) + 1;
  const minutes = rawMinutes === '00' ? '' : `:${rawMinutes}`;
  return { hoursMinutes: `${hours}${minutes}`, amOrPm };
};

export const getMonthRangeBetweenDates = (startDate?: string, endDate?: string): Date[] | [] => {
  if (!startDate || !endDate) {
    return [];
  }
  const formattedStartDate = parseISO(startDate).toISOString();
  const formattedEndDate = parseISO(endDate).toISOString();

  const interval = {
    start: new Date(formattedStartDate),
    end: new Date(formattedEndDate),
  };

  return eachMonthOfInterval(interval);
};

export const formatDateWithAbbreviatedDayAndMonth = (date: Date): string =>
  format(date, 'EEE, MMM d, yyyy');
