import type { GetHotelGalleryPageQuery } from '@dx-ui/queries-dx-property-ui';
import type { Metrics } from '../../../helpers/metrics/metrics.types';

export const GalleryImagePrimaryCategory = [
  'all',
  'hotel',
  'resort',
  'rooms',
  'dining',
  'amenities',
  'spa',
  'golf',
  'events',
] as const;

type IndexableKeys = {
  [key in Exclude<(typeof GalleryImagePrimaryCategory)[number], 'all'>]: {
    categoryName: string[];
    hotelFeatures: string[];
    roomFeatures: string[];
  };
};

export const galleryPrimaryCategoryMapper: IndexableKeys = {
  hotel: {
    categoryName: ['hotel'],
    hotelFeatures: [
      'Architecture Detail',
      'Atrium',
      'Building',
      'Concierge Desk',
      'Courtyard',
      'Exterior',
      'Front Desk',
      'Furnishings',
      'Garden',
      'Lobby',
      'Patio',
      'Public Restrooms',
    ],
    roomFeatures: [],
  },
  resort: {
    categoryName: ['hotel'],
    hotelFeatures: [
      'Architecture Detail',
      'Atrium',
      'Building',
      'Concierge Desk',
      'Courtyard',
      'Exterior',
      'Front Desk',
      'Furnishings',
      'Garden',
      'Lobby',
      'Patio',
      'Public Restrooms',
    ],
    roomFeatures: [],
  },
  rooms: {
    categoryName: ['room'],
    hotelFeatures: ['Guest Room'],
    roomFeatures: [
      'Bathroom',
      'Bed',
      'Entry',
      'Kitchen',
      'Lounge Area',
      'Outside View',
      'Room Technology',
      'Shower',
      'Tub',
      'Work Desk',
    ],
  },
  dining: {
    categoryName: ['restaurant'],
    hotelFeatures: [
      'Food and Beverage Spaces',
      'Bar',
      'Breakfast Area',
      'Coffee Shop',
      'Restaurant',
      'Snack Shop',
    ],
    roomFeatures: [],
  },
  amenities: {
    categoryName: ['activity', 'hotel', 'restaurant'],
    hotelFeatures: [
      'Fitness Center',
      'Hot Tub',
      'Pool',
      'Recreational Facilities',
      'Spa',
      'Spa Court',
      'Casino',
      'Business Center',
      'Executive Lounge',
      'Gift Shop',
      'Business Center',
    ],
    roomFeatures: [],
  },
  spa: {
    categoryName: ['activity'],
    hotelFeatures: ['Spa'],
    roomFeatures: [],
  },
  golf: {
    categoryName: ['golf'],
    hotelFeatures: ['Golf'],
    roomFeatures: [],
  },
  events: {
    categoryName: ['hotel', 'meetingSpace'],
    hotelFeatures: ['Ballroom', 'Boardroom', 'Conference and Meeting Spaces', 'Meeting Room'],
    roomFeatures: [],
  },
};

export type TGalleryImageSet =
  | NonNullable<NonNullable<NonNullable<GetHotelGalleryPageQuery>['hotel']>['images']>['gallery']
  | NonNullable<NonNullable<NonNullable<GetHotelGalleryPageQuery>['hotel']>['images']>['spaGallery']
  | NonNullable<
      NonNullable<NonNullable<GetHotelGalleryPageQuery>['hotel']>['images']
    >['golfGallery'];

export type TGalleryProps = {
  e2e?: string;
  gallery?: TGalleryImageSet;
  metrics?: Metrics;
  galleryCategoryName?: (typeof GalleryImagePrimaryCategory)[number];
};

export type TGalleryTabs =
  | {
      [key in (typeof GalleryImagePrimaryCategory)[number]]: TGalleryImageSet | [];
    }
  | null;

export type TGalleryImage = {
  e2e?: string;
  extraAltText: string;
  handleClick: () => void;
  className?: string;
  fullImage?:
    | NonNullable<
        NonNullable<NonNullable<GetHotelGalleryPageQuery>['hotel']>['images']
      >['gallery'][number]['fullImage']
    | null;
  thumbImage?:
    | NonNullable<
        NonNullable<NonNullable<GetHotelGalleryPageQuery>['hotel']>['images']
      >['gallery'][number]['thumbImage']
    | null;
  index: number;
};

export type TGalleryGridModal = {
  isModalOpen: boolean;
  closeModal: () => void;
  e2e?: string;
  gallery?: TGalleryImageSet;
  initialSlideIndex: number;
  ariaLabel?: string;
  galleryCategoryName?: (typeof GalleryImagePrimaryCategory)[number];
  dialogTitle: string;
};
