import type { RefObject } from 'react';
import { useState } from 'react';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

export const useStickyLayout = ({
  ref: stickyWrapperRef,
  thresholdSticky = 33,
}: {
  ref: RefObject<HTMLElement>;
  thresholdSticky?: number;
}): { isWrapperSticky: boolean } => {
  const [isSticky, setIsSticky] = useState(false);
  const checkForSticky = useDebounceCallback((height: number) => {
    const windowHeight = window.innerHeight;
    const wrapperHeight = height ?? stickyWrapperRef.current?.offsetHeight;
    if (windowHeight > 0 && wrapperHeight) {
      const heightVh = Math.round((wrapperHeight / windowHeight) * 100);
      const isSticky = heightVh < thresholdSticky;
      setIsSticky(isSticky);
    }
  }, 200);

  useResizeObserver({
    ref: stickyWrapperRef,
    onResize: ({ height = 0 }) => {
      checkForSticky(height);
    },
  });

  return { isWrapperSticky: isSticky };
};
