import { forwardRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

type ActionButton = {
  adaDescription?: string;
  label?: string;
} & React.ComponentPropsWithRef<'button'>;

type ShouldEnableActionButton = ActionButton & { isEnabled?: boolean };

export type ActionButtonProps = {
  /** Props passed to each button type. */
  buttonOptions?: {
    cancel?: ActionButton;
    confirm?: ActionButton;
    reset?: ShouldEnableActionButton;
  };
  /** Hander called when cancel button is blurred */
  onCancel?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  /** Handler called when done button is blurred. */
  onConfirm?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  /** Handler called when reset is activated */
  onReset?: () => void;
  /** stacks action buttons in viewport widths md and up */
  shouldStackButtons?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ActionButton>(
  ({ label, adaDescription, className, ...rest }, forwardedRef) => {
    return (
      <button {...rest} className={cx('btn btn-lg', className)} ref={forwardedRef} type="button">
        {label}
        {adaDescription ? <span className="sr-only">{adaDescription}</span> : null}
      </button>
    );
  }
);

Button.displayName = 'Button';

export function ActionButtons({
  buttonOptions = {},
  onReset,
  onConfirm,
  onCancel,
  shouldStackButtons = false,
}: ActionButtonProps) {
  const { t } = useTranslation('osc-dialog');
  const { cancel, confirm, reset } = buttonOptions;
  const resetBtnEnabled = reset?.isEnabled && reset?.label;

  const buttons = [
    {
      ...confirm,
      onClick: onConfirm,
      label: confirm?.label ?? t('done'),
      className: 'btn-primary',
      'data-testid': 'shop-modal-done-cta',
      ref: confirm?.ref,
    },
    {
      ...cancel,
      onClick: onCancel,
      label: cancel?.label ?? t('cancel'),
      className: 'btn-primary-outline',
      ref: cancel?.ref,
    },
  ].map((props) => (
    <Dialog.Close key={props.label} asChild>
      <Button {...props} key={props.label} />
    </Dialog.Close>
  ));

  return (
    <div
      className={cx('bg-bg sticky bottom-0 flex flex-col gap-4 px-1 pb-4 pt-2', {
        'md:justify-between': resetBtnEnabled,
        'md:justify-end': !resetBtnEnabled,
        'md:flex-row': !shouldStackButtons,
      })}
    >
      {resetBtnEnabled ? (
        <div className="md:self-center">
          <button
            className="btn-xl btn btn-primary-link w-full rounded-none"
            data-testid="shop-modal-reset-cta"
            onClick={() => onReset?.()}
            ref={reset?.ref}
            type="button"
          >
            {reset?.label}
            {reset?.adaDescription ? (
              <span className="sr-only" aria-live="assertive" role="alert">
                {reset?.adaDescription}
              </span>
            ) : null}
          </button>
        </div>
      ) : null}
      <div
        className={cx('flex flex-col gap-3', {
          'md:flex-row': !shouldStackButtons,
        })}
      >
        {buttons}
      </div>
    </div>
  );
}
