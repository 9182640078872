import { useAuth } from '@dx-ui/framework-auth-provider';
import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import type { FormDefaultValues } from '@dx-ui/osc-shop-form';
import { ShopForm, wrapperClassNameDefault } from '@dx-ui/osc-shop-form';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useIsClient } from 'usehooks-ts';
import { CONDUCTRICS_GOALS } from '../../helpers/constants';
import { baseUrl } from '../../helpers/env-vars';
import { useSearchContext } from '../../helpers/providers/search/search-provider';
import type { TSearchWidget } from './search.types';
import { SearchWidgetForm } from './SearchWidgetForm';

const SearchWidget = ({
  ctyhocn,
  isGroupSearch,
  showNumAttendees,
  ageBasedPricing,
  isAdultsOnly,
  adultAge,
  isPartnerBrand,
  brandCode,
  maxOccupants,
  maxNumRooms,
  hideFlexDates,
  currencyCode,
  minArrivalDate,
  maxArrivalDate,
}: TSearchWidget) => {
  const { searchState, onSearchChange, initializeSearchState } = useSearchContext();
  const { isReady, locale } = useRouter();
  const language = locale || 'en';
  const { guestInfo } = useAuth();
  const { t } = useTranslation('dx-search');
  const isClient = useIsClient();

  const defaultValues: FormDefaultValues = useMemo(() => {
    return {
      brandCode,
      dates: {
        arrivalDate: searchState?.dates?.arrivalDate,
        departureDate: searchState?.dates?.departureDate,
        datesFlex: searchState?.dates?.datesFlex,
      },
      rooms: searchState?.rooms,
      hhonors: guestInfo?.hhonors,
      query: ctyhocn,
      numAttendees: isGroupSearch ? searchState?.numAttendees || 0 : null,
      numRooms: isGroupSearch ? searchState?.numRooms ?? 10 : null,
      specialRates: searchState?.specialRates,
    };
  }, [
    brandCode,
    ctyhocn,
    guestInfo?.hhonors,
    isGroupSearch,
    searchState?.dates?.arrivalDate,
    searchState?.dates?.datesFlex,
    searchState?.dates?.departureDate,
    searchState?.numAttendees,
    searchState?.numRooms,
    searchState?.rooms,
    searchState?.specialRates,
  ]);

  useEffect(() => {
    if (isReady && !isEqual(defaultValues, searchState)) {
      onSearchChange(defaultValues);
    }
  }, [defaultValues, isReady, onSearchChange, searchState]);

  const searchFormKey = isBrowser
    ? window.btoa(encodeURI(JSON.stringify(defaultValues)))
    : 'search-form';

  if (!isClient) {
    return null;
  }

  return (
    <ShopForm
      additionalQSParameters={{ displayCurrency: searchState?.displayCurrency }}
      defaultValues={defaultValues}
      language={language}
      wrapperClassName={cx([...wrapperClassNameDefault, 'w-fit'].slice(1))}
      submitOpensNewTab={true}
      targetOHWPage="book"
      key={searchFormKey}
      cta={t('ctaButton')}
      hasErrorBanner={false}
      onSubmit={async ({ url }) => {
        await sendRewardAsync(CONDUCTRICS_GOALS.EDIT_SEARCH_WIDGET);
        window.open(baseUrl + url, '_blank', 'noopener');
      }}
    >
      <SearchWidgetForm
        language={language}
        onSearchChange={onSearchChange}
        initializeSearchState={initializeSearchState}
        isGroupSearch={isGroupSearch}
        showNumAttendees={showNumAttendees}
        searchState={searchState || defaultValues}
        ageBasedPricing={ageBasedPricing}
        isAdultsOnly={isAdultsOnly}
        adultAge={adultAge}
        isPartnerBrand={isPartnerBrand}
        maxOccupants={maxOccupants}
        maxNumRooms={maxNumRooms}
        hideFlexDates={hideFlexDates}
        minArrivalDate={minArrivalDate}
        maxArrivalDate={maxArrivalDate}
        ctyhocn={ctyhocn}
        currencyCode={currencyCode}
      />
    </ShopForm>
  );
};

export default SearchWidget;
