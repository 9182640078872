import type { ShopFormRoom } from '@dx-ui/osc-rooms';
import { parse, startOfToday } from 'date-fns';
import type { ReadonlyURLSearchParams } from 'next/navigation';
import { SEARCH_STATE_QUERY_PARAMS } from '../../helpers/constants';
import { stringOrBooleanOrNull } from '../../helpers/utils/client';
import type { AdultsAndChildrenRoom, InitialSearch, RoomAges } from './search.types';

export const availableSearchParamsMapper = (searchQueryParams: ReadonlyURLSearchParams) =>
  SEARCH_STATE_QUERY_PARAMS.AVAILABLE.reduce<InitialSearch>(
    (accumulatedParams, param) => {
      const isValidParam = searchQueryParams.has(param);
      const paramValue = searchQueryParams.get(param);
      const isDates = SEARCH_STATE_QUERY_PARAMS.DATES.includes(param);
      const isRoom = SEARCH_STATE_QUERY_PARAMS.ROOM_NUM.includes(param);
      const isAges = SEARCH_STATE_QUERY_PARAMS.ROOM_AGES.includes(param);
      const isSpecialRate = SEARCH_STATE_QUERY_PARAMS.SPECIAL_RATES.includes(param);

      if (isValidParam && isDates && paramValue) {
        const paramAsKeyValue =
          param === 'flexibleDates'
            ? { datesFlex: !!stringOrBooleanOrNull(paramValue) }
            : { [param]: formatDateString(paramValue || '') };

        return {
          ...accumulatedParams,
          dates: {
            ...accumulatedParams.dates,
            ...paramAsKeyValue,
          },
        };
      }

      if (isValidParam && isSpecialRate) {
        let offerId = accumulatedParams.specialRates?.offerId || undefined;
        const paramAsKeyValue =
          param === 'promotionCode'
            ? { promoCode: paramValue || undefined }
            : { [param]: stringOrBooleanOrNull(paramValue) };

        if (hasValidOfferId(param, paramValue)) {
          offerId = { id: Number(paramValue) };
        }

        return {
          ...accumulatedParams,
          specialRates: {
            ...accumulatedParams.specialRates,
            ...paramAsKeyValue,
            offerId,
          },
        };
      }

      if (isValidParam && isAges) {
        const ageKeyValuePair = {
          roomNumber: roomNumFromParam(param),
          childAges: paramValue ? paramValue.split(',').map((age) => ({ age: Number(age) })) : [],
        };

        const ageArray = !!accumulatedParams?.ages
          ? [...accumulatedParams.ages, ageKeyValuePair]
          : [ageKeyValuePair];

        return {
          ...accumulatedParams,
          ages: ageArray,
        };
      }

      if (isValidParam && isRoom) {
        return {
          ...accumulatedParams,
          rooms: [...accumulatedParams.rooms, sortAdultsAndChildrenRooms(param, searchQueryParams)],
        };
      }

      if (isValidParam && !isSpecialRate && !isRoom) {
        return { ...accumulatedParams, [param]: paramValue };
      }

      return { ...accumulatedParams };
    },
    { rooms: [] } as InitialSearch
  );

export const formatDateString = (unformattedDate: string): Date =>
  parse(unformattedDate, 'yyyy-MM-dd', startOfToday());

export const shapeRoomsToDefaultDataStructure = (
  initialRooms: AdultsAndChildrenRoom[],
  roomAges: RoomAges[] | undefined
): ShopFormRoom[] => {
  const mappedRooms = new Map<number, AdultsAndChildrenRoom>();

  initialRooms.forEach((initialRoom) => {
    if (!mappedRooms.has(initialRoom.roomNumber)) {
      mappedRooms.set(initialRoom.roomNumber, { roomNumber: initialRoom.roomNumber });
    }

    const existingRooms = mappedRooms.get(initialRoom.roomNumber)!;

    mappedRooms.set(initialRoom.roomNumber, { ...existingRooms, ...initialRoom });
  });

  if (roomAges?.length) {
    roomAges.forEach((room) => {
      const mappedRoom = mappedRooms.get(room.roomNumber);

      if (
        mappedRooms.has(room.roomNumber) &&
        room.childAges.length &&
        mappedRoom?.children?.length &&
        room.childAges.length === mappedRoom?.children?.length
      ) {
        mappedRooms.set(room.roomNumber, { ...mappedRoom, children: room.childAges });
      }
    });
  }

  return Array.from(mappedRooms.values()).map((room) => {
    return {
      adults: room.adults,
      children: room.children || [],
    };
  }) as ShopFormRoom[];
};

export const roomNumFromParam = (param: string): number => {
  const matchedRoomNumber = param.match(/\d+/);

  return (matchedRoomNumber?.length && Number(matchedRoomNumber[0])) || 0;
};

export const sortAdultsAndChildrenRooms = (
  roomParam: string,
  searchQueryParams: ReadonlyURLSearchParams
): AdultsAndChildrenRoom => {
  const roomNumber = roomNumFromParam(roomParam);
  const paramValue = searchQueryParams.get(roomParam) || '';

  if (
    roomParam
      .toLocaleLowerCase()
      .match(/NumChildren/i)
      ?.includes('numchildren')
  ) {
    if (parseInt(paramValue) > 0) {
      const nullChildrenCount = Array.from({ length: parseInt(paramValue) }, () => ({
        age: null,
      }));
      return { children: nullChildrenCount, roomNumber };
    }

    return { children: [], roomNumber };
  }

  if (
    roomParam
      .toLocaleLowerCase()
      .match(/NumAdult/i)
      ?.includes('numadult')
  ) {
    return { adults: parseInt(paramValue), roomNumber };
  }

  return { roomNumber };
};

const hasValidOfferId = (param: string, paramValue: string | null) =>
  param === 'offerId' && paramValue;
