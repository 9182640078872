import type { LoginResponse } from '@dx-ui/osc-login';
import type { HeaderProps, TDrawerItem } from '@dx-ui/osc-header';
import { Header } from '@dx-ui/osc-header';
import { useAuth, getGuestFirstName } from '@dx-ui/framework-auth-provider';
import { goUserTypes, CONDUCTRICS_GOALS } from '../../helpers/constants';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { useMetrics } from '../../hooks/use-metrics';
import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import { generateUrl } from '../../helpers/utils/client';
import { useRouter } from 'next/router';
import { mapCustomClassesToComponent } from '../../helpers/themes/customTheme';

export type THeaderWrapper = {
  brandCode: string;
  brandName: string;
  lang: string;
  mainNavLinks?: TDrawerItem[];
  suppressLogo?: boolean;
  ctyhocn: string;
};

export const HeaderWrapper = function ({
  brandCode,
  brandName,
  lang,
  mainNavLinks,
  suppressLogo,
  ctyhocn,
}: THeaderWrapper) {
  const { login, logout, guestInfo, isAuthenticated } = useAuth();
  const { isTailored } = useGlobalConfig();
  const metrics = useMetrics();
  const router = useRouter();

  const onSignInAttempt = async (data: LoginResponse): Promise<void> => {
    if (data) {
      let availableGoUserTypes: string[] = [];
      // TODO: NHCBP-3343 - verify packages is right
      if (guestInfo?.hhonors?.packages?.filter) {
        availableGoUserTypes = guestInfo?.hhonors?.packages
          ?.filter((pack) => pack?.packageName && goUserTypes.includes(pack.packageName))
          ?.map((pack) => pack && pack.packageName);
      }
      metrics.trackUserLoggedIn([
        {
          hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
          tierName: guestInfo?.hhonors?.summary?.tierName || '',
          // TODO: NHCBP-3343 - verify whether this should be 'totalPoints' or 'totalPointsFmt' (it was 'totalPoints', but that is a Number)
          points: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
          goUserTypes: availableGoUserTypes,
        },
      ]);
    }
    await login(data);
    if (data?.data) {
      await sendRewardAsync(CONDUCTRICS_GOALS.SUCCESSFUL_LOGIN_FROM_PROPERTY);
    }
  };

  const onSignOut = logout;
  const { relativeUrl } = generateUrl(router);

  const customClassName = mapCustomClassesToComponent('globalNav', brandCode, isTailored);

  const headerProps: HeaderProps = {
    brand: {
      code: brandCode,
      name: brandName,
      url: relativeUrl,
    },
    className: `border-border border-b border-solid ${customClassName}`,
    isFluid: true,
    megaNavLinks: mainNavLinks,
    onSignInAttempt,
    onSignOut,
    showNewHeader: true,
    suppressLogo,
    user:
      isAuthenticated && guestInfo
        ? {
            name: getGuestFirstName({ guestInfo }),
            honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
            honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
            honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
          }
        : undefined,
    languageSelectorOptions: {
      locale: lang,
      ctyhocn,
      appName: 'dx-property-ui',
      popupOptions: {
        includeTitles: true,
      },
      buttonOptions: {
        className: 'text-sm',
      },
    },
  };

  return <Header {...headerProps} />;
};
