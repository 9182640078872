import { env } from '@dx-ui/framework-env';
import type { BaseRouter } from 'next/dist/shared/lib/router/router';
import type { StaticOfferQuery } from '@dx-ui/content-offers-components';
import { slugifyOfferName } from '@dx-ui/content-offers-components';
import type { NextRouter, useRouter } from 'next/router';

export const generateUrl = (
  nextRouter: BaseRouter,
  subpage?: string
): { relativeUrl: string; fullyQualifiedUrl: string } => {
  const {
    asPath,
    locale,
    query: { hotelSlug },
  } = nextRouter;
  const pathWithoutParams = asPath ? asPath.split('?')[0] || '' : '';
  const pathBetweenLocaleAndSubPage = pathWithoutParams.split(`${hotelSlug}/`)[0];
  const withLocale = locale ? `/${locale}` : '';
  const withSubpage = subpage ? `/${subpage}/` : '/';

  return {
    fullyQualifiedUrl: `${env(
      'HILTON_ASSETS_URI'
    )}${withLocale}${pathBetweenLocaleAndSubPage}${hotelSlug}${withSubpage}`,
    relativeUrl: `${withLocale}${pathBetweenLocaleAndSubPage}${hotelSlug}${withSubpage}`,
  };
};

export function getCanonicalUrl({
  offer,
  router,
  baseUrl,
}: {
  offer: StaticOfferQuery;
  router: NextRouter;
  baseUrl: string;
}) {
  if (offer?.staticOffer?.type === 'brand') {
    return `${baseUrl}/${router.locale}/offers/${slugifyOfferName(offer.staticOffer)}/`;
  }
  // otherwise this the canonical url should be itself
  return `${baseUrl}/${router.locale}${router.asPath}`;
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const replaceAll = (str: string, find: string, replace: string) =>
  str.replace(new RegExp(escapeRegExp(find), 'g'), replace);

export const restaurantNameToUrl = (name: string, encoded: boolean = false) => {
  const base_url = replaceAll(
    replaceAll(replaceAll(name, "'", ''), '&', 'and'),
    ' ',
    '-'
  ).toLowerCase();
  return encoded ? encodeURIComponent(base_url) : base_url;
};

export function constructOfferDetailPageUrl(
  router: ReturnType<typeof useRouter>,
  offer: { name?: string; id?: number }
) {
  return `/${router.locale}${router.pathname.replace(
    '[hotelSlug]',
    router.query.hotelSlug as string
  )}/${slugifyOfferName(offer)}/`;
}

export const getCtyhocnFromSlug = (slug: string | string[] = ''): string => {
  const slugString = Array.isArray(slug) ? slug.join('') : slug;
  const match = slugString.match(/^([A-Z]{4}-[A-Z]{2}|[A-Z]{7})-?.*/i);
  return match && match[1] ? match[1].toUpperCase() : '';
};
