import { useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { Login } from '@dx-ui/osc-login';
import { useTranslation } from 'next-i18next';
import { Link } from '@dx-ui/osc-link';
import { DrawerPanel } from '@dx-ui/osc-drawer';
import cx from 'classnames';
import MegaMenu from './mega-menu';
import { DrawerList } from './nav-drawer-list';
import Icon from '@dx-ui/osc-icon';
import { getNHCBP5758MVTVariant, sendInteractionReward, trackNavClick } from '../header.utilities';
import { LanguageSelector } from '@dx-ui/osc-language-selector';
import { sendReward } from '@dx-ui/framework-conductrics';
import { HeaderLogoLink } from '../header.logo';
import type { TBrandHeaderBody } from '../types';
import { useRouter } from 'next/router';
import { getLocaleFormattedNumber } from '@dx-ui/framework-i18n';
import { useActiveUserLinks } from '../hooks/useActiveUserLinks';
import { NavItemWithSpeechBubble } from './mvtest-nhcbp-5758/nav-item-speech-bubble';

export const BrandHeaderBody = ({
  menuItems,
  brand,
  loginOptions,
  user,
  SignUpComponent,
  wrapperId,
  onSignInAttempt,
  languageSelectorOptions,
  onSignOut,
  isFluid,
  suppressLogo,
  shouldSuppressFindStay,
  shouldSuppressJoinAndSignIn,
  shouldOpenAccountLinksInNewWindow,
  shouldSuppressHonorsAccountLinks,
  heading,
  theme,
  mvts,
}: TBrandHeaderBody) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const { t } = useTranslation(['osc-header', 'osc-login']);
  const userLoggedInButtonRef = useRef<HTMLButtonElement>(null);

  const activeUserLinks = useActiveUserLinks();

  const closeMenus = () => {
    setIsMenuOpen(false);
    setIsAccountOpen(false);
    document.removeEventListener('keydown', handleKeyDown);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeMenus();
    }
  };

  const addEscapeKeyListener = () => {
    document.addEventListener('keydown', handleKeyDown);
  };

  const removeEscapeKeyListener = () => {
    document.removeEventListener('keydown', handleKeyDown);
  };

  const menuPressed = () => {
    sendInteractionReward();
    if (isAccountOpen) setIsAccountOpen(false);
    if (isMenuOpen) {
      setIsMenuOpen(false);
      removeEscapeKeyListener();
    } else {
      addEscapeKeyListener();
      setIsMenuOpen(true);
    }
  };

  const accountPressed = () => {
    sendInteractionReward();
    sendReward('profile-name-click');
    setIsMenuOpen(false);
    if (isAccountOpen) {
      setIsAccountOpen(false);
      removeEscapeKeyListener();
    } else {
      setIsAccountOpen(true);
      addEscapeKeyListener();
    }
  };

  const closeMainMenu = () => {
    setIsMenuOpen(false);
  };

  const closeAccount = () => {
    setIsAccountOpen(false);
  };

  const isDark = theme === 'dark';

  const joinButtonClasses = cx(
    'brand-wa:font-normal brand-ey:font-normal border-border brand-lx:border-primary m-auto inline-block border-e pe-2 text-xs font-semibold',
    {
      'text-text-inverse': isDark,
    }
  );

  const { isGlowVariant, isSpeechBubble, variant } = getNHCBP5758MVTVariant(mvts);

  return (
    <>
      <nav
        id="drawer-nav"
        className={cx('bg-bg z-50 lg:hidden', {
          'bg-bg brand-ou:bg-secondary': !theme,
          'bg-bg-dark': isDark,
        })}
      >
        <div
          className={cx('flex content-center items-center p-2 md:px-8 lg:px-10', {
            'sm:h-16': !!languageSelectorOptions,
            'h-min px-2 py-1': !languageSelectorOptions,
          })}
        >
          {menuItems && menuItems.length > 0 ? (
            <button
              className={cx('me-2 w-12', {
                'self-end sm:self-center': !!languageSelectorOptions,
              })}
              onClick={menuPressed}
              type="button"
              aria-expanded={isMenuOpen}
              id="menu-open-button"
            >
              <Icon
                name="menu"
                size="md"
                className={cx('m-auto block', {
                  'text-text-inverse': isDark,
                })}
              />
              <span
                className={cx('brand-wa:font-normal brand-ey:font-normal text-xs font-semibold', {
                  'text-text-inverse': isDark,
                })}
              >
                <span className="sr-only">{t('open')}</span>
                {t('menu')}
              </span>
            </button>
          ) : null}
          {!suppressLogo ? (
            <HeaderLogoLink
              theme={theme}
              brand={brand}
              className={cx({
                'self-end sm:self-center': !!languageSelectorOptions,
              })}
              heading={heading}
            />
          ) : null}
          <div className="ms-auto flex flex-col items-center gap-x-2 sm:flex-row">
            {languageSelectorOptions ? (
              <div className="pb-5 sm:pb-0">
                <LanguageSelector theme={theme} {...languageSelectorOptions} />
              </div>
            ) : null}
            <div className="ms-auto flex items-center gap-x-2">
              {activeUserLinks?.findStayLink && !shouldSuppressFindStay ? (
                <Link
                  className="mb-1"
                  anchorClassName="text-center min-w-16 w-min align-middle"
                  underline={false}
                  url={activeUserLinks?.findStayLink.url}
                  isNewWindow={shouldOpenAccountLinksInNewWindow}
                  showNewWindowIcon={false}
                  onClick={() => {
                    sendInteractionReward();
                    sendReward('find-stay-click');
                    trackNavClick('gh_findstay');
                  }}
                >
                  {isGlowVariant && !user ? (
                    <span className="brand-wa:font-normal brand-ey:font-normal hover:text-primary inline-block text-xs font-semibold hover:underline">
                      {t('findStay')}
                    </span>
                  ) : (
                    <>
                      <Icon
                        name="calendar"
                        size="md"
                        className={cx('m-auto block', {
                          'nav-icon': !theme,
                          'text-text-inverse': isDark,
                        })}
                      />
                      <span
                        className={cx(
                          'brand-wa:font-normal brand-ey:font-normal inline-block text-xs font-semibold',
                          {
                            'text-text-inverse': isDark,
                          }
                        )}
                      >
                        {t('findStay')}
                      </span>
                    </>
                  )}
                </Link>
              ) : null}
              {isGlowVariant && !user ? <div className="bg-border h-7 w-px" /> : null}
              {user ? (
                <button
                  className="items-center"
                  onClick={() => {
                    trackNavClick('gh_membername');
                    accountPressed();
                  }}
                  aria-expanded={isAccountOpen}
                  type="button"
                >
                  <Icon
                    name="user-circle"
                    size="md"
                    className={cx('m-auto block', {
                      'nav-icon': !theme,
                      'nav-icon-dark': isDark,
                    })}
                  />
                  <span
                    className={cx(
                      'brand-ey:font-normal brand-wa:font-normal break-all text-xs font-semibold',
                      {
                        'text-text-inverse': isDark,
                      }
                    )}
                  >
                    {t('greetings', { username: user.name })}
                  </span>
                </button>
              ) : (activeUserLinks?.signInLink || activeUserLinks?.signUpLink) &&
                !shouldSuppressJoinAndSignIn ? (
                <div className={cx('flex flex-col', { 'pt-7': !isGlowVariant })}>
                  <div className="flex items-center">
                    {activeUserLinks?.signUpLink && !SignUpComponent ? (
                      <div className={cx('h-fit', { 'ms-1': isGlowVariant })}>
                        <Link
                          {...activeUserLinks.signUpLink}
                          showNewWindowIcon={false}
                          underline={false}
                          className={
                            isGlowVariant
                              ? 'hover:text-primary inline-block pe-2 text-xs font-semibold hover:underline'
                              : joinButtonClasses
                          }
                          onClick={() => {
                            sendInteractionReward();
                            sendReward('nav-join-click');
                            trackNavClick('gh_join_signin');
                          }}
                        >
                          {t('signUp')}
                        </Link>
                      </div>
                    ) : null}
                    {SignUpComponent ? (
                      <div className="h-fit">
                        <SignUpComponent
                          className={joinButtonClasses}
                          loggedInButtonRef={userLoggedInButtonRef}
                        >
                          {t('signUp')}
                        </SignUpComponent>
                      </div>
                    ) : null}
                    {activeUserLinks.signInLink && isSpeechBubble ? (
                      <div
                        className="ps-2"
                        onClick={() => {
                          sendInteractionReward();
                          trackNavClick('gh_join_signin');
                        }}
                      >
                        <NavItemWithSpeechBubble
                          frameSrc={activeUserLinks?.signInLink?.url || ''}
                          title={t('osc-login:signIn')}
                          onLoginAttempt={onSignInAttempt}
                          variant={variant}
                          buttonClassName={cx(
                            'brand-wa:!font-normal brand-ey:font-normal text-xs font-semibold',
                            {
                              'text-text-inverse': isDark,
                            }
                          )}
                          onDialogOpen={() => {
                            setIsMenuOpen(false);
                            setIsAccountOpen(false);
                          }}
                          {...loginOptions}
                        >
                          <Icon
                            name="user-circle"
                            size="md"
                            className={cx('-ms-[22px] -mt-7 block', {
                              'nav-icon': !theme,
                              'nav-icon-dark': isDark,
                            })}
                          />
                          <span>{t('osc-login:signIn')}</span>
                        </NavItemWithSpeechBubble>
                      </div>
                    ) : null}
                    {activeUserLinks.signInLink && !isSpeechBubble ? (
                      <div
                        className="ps-2"
                        onClick={() => {
                          sendInteractionReward();
                          trackNavClick('gh_join_signin');
                        }}
                      >
                        {!isGlowVariant ? (
                          <Icon
                            name="user-circle"
                            size="md"
                            className={cx('-ms-[22px] -mt-7 block', {
                              'nav-icon': !theme,
                              'nav-icon-dark': isDark,
                            })}
                          />
                        ) : null}
                        <Login
                          frameSrc={activeUserLinks?.signInLink?.url || ''}
                          title={t('osc-login:signIn')}
                          onLoginAttempt={onSignInAttempt}
                          hideIcon={true}
                          onOpen={() => {
                            setIsMenuOpen(false);
                            setIsAccountOpen(false);
                          }}
                          buttonClassName={
                            isGlowVariant
                              ? 'btn btn-primary-outline text-xs font-semibold transition-shadow duration-300 ease-in-out shadow-[0_0_15px_rgb(0,144,147)] hover:shadow-none'
                              : cx(
                                  'brand-wa:!font-normal brand-ey:font-normal text-xs font-semibold',
                                  {
                                    'text-text-inverse': isDark,
                                  }
                                )
                          }
                          {...loginOptions}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {(isMenuOpen || isAccountOpen) && (
          <div
            className="bg-overlay animate-fadein absolute top-0 z-50 h-screen w-full overflow-hidden backdrop-blur-[2px]"
            data-testid="backgroundOverlay"
            onClick={closeMenus}
          />
        )}
        {menuItems && menuItems.length > 0 ? (
          <div
            data-testid="menuWindow"
            className={cx({ visible: isMenuOpen, invisible: !isMenuOpen })}
          >
            <DrawerPanel
              isOpen={isMenuOpen}
              alignment="left"
              appWrapperId={wrapperId}
              interactiveElementWrapperId="drawer-nav"
              returnFocus={() => {
                document.getElementById('menu-open-button')?.focus();
                return false;
              }}
            >
              <DrawerList isOpen={isMenuOpen} menuItems={menuItems} onClose={closeMainMenu} />
            </DrawerPanel>
          </div>
        ) : null}
        <DrawerPanel
          isOpen={isAccountOpen}
          alignment="right"
          appWrapperId={wrapperId}
          interactiveElementWrapperId="drawer-nav"
        >
          {user && isAccountOpen ? (
            <AccountSection
              user={user}
              userLinks={activeUserLinks}
              onClose={closeAccount}
              onSignOut={onSignOut}
              shouldSuppressHonorsAccountLinks={shouldSuppressHonorsAccountLinks}
              shouldOpenAccountLinksInNewWindow={shouldOpenAccountLinksInNewWindow}
            />
          ) : null}
        </DrawerPanel>
      </nav>

      <nav className="hidden lg:block">
        <MegaMenu
          isFluid={isFluid}
          suppressLogo={suppressLogo}
          shouldSuppressHonorsAccountLinks={shouldSuppressHonorsAccountLinks}
          shouldSuppressFindStay={shouldSuppressFindStay}
          shouldSuppressJoinAndSignIn={shouldSuppressJoinAndSignIn}
          shouldOpenAccountLinksInNewWindow={shouldOpenAccountLinksInNewWindow}
          brand={brand}
          onSignInAttempt={onSignInAttempt}
          onSignOut={onSignOut}
          user={user}
          userLinks={activeUserLinks}
          mainNavLinks={menuItems}
          languageSelectorOptions={languageSelectorOptions}
          loginOptions={loginOptions}
          heading={heading}
          theme={theme}
          mvts={mvts}
          SignUpComponent={SignUpComponent}
        />
      </nav>
    </>
  );
};

export type TAccountSection = Pick<
  TBrandHeaderBody,
  | 'userLinks'
  | 'onSignOut'
  | 'shouldSuppressHonorsAccountLinks'
  | 'shouldOpenAccountLinksInNewWindow'
> &
  Required<Pick<TBrandHeaderBody, 'user'>> & {
    onClose: () => void;
  };

const AccountUserLink = ({
  url,
  onClick,
  children,
  isNewWindow,
}: {
  url: string;
  onClick: () => void;
  children: React.ReactNode;
  isNewWindow?: boolean;
}) => {
  return (
    <Link
      anchorClassName={cx(
        'border-border block px-6 py-2',
        'brand-ey:border-primary-alt brand-ey:hover:bg-tertiary-alt brand-ey:focus:bg-tertiary-alt',
        'brand-hi-refresh:hover:bg-bg-light brand-hi-refresh:focus:bg-bg-light',
        'brand-lx:hover:bg-bg-light brand-lx:focus:bg-bg-light',
        'brand-ou:border-primary brand-ou:hover:bg-bg-light brand-ou:focus:bg-bg-light'
      )}
      className="brand-ey:font-normal brand-gu:font-normal brand-gu:font-mono brand-hi-refresh:font-normal brand-nd:font-medium brand-nd:font-mono text-text font-bold"
      underline={false}
      url={url}
      onClick={onClick}
      isNewWindow={isNewWindow}
    >
      {children}
    </Link>
  );
};

const AccountSection = ({
  user,
  userLinks,
  onClose,
  onSignOut,
  shouldSuppressHonorsAccountLinks,
  shouldOpenAccountLinksInNewWindow,
}: TAccountSection) => {
  const { locale = 'en' } = useRouter();

  const { t } = useTranslation('osc-header');
  const formattedHonorsPointsTotal = getLocaleFormattedNumber(user.honorsPoints, { locale });

  return (
    <FocusLock returnFocus>
      <div className="border-border brand-ey:border-primary-alt brand-ou:border-primary relative mb-2 border-b px-4 py-3">
        <button onClick={onClose} type="button" className="px-2">
          <Icon name="close" size="md" className="m-auto" />
          <span className="brand-ey:font-normal brand-wa:font-normal text-xs font-semibold">
            {t('close')}
            <span className="sr-only">{t('closeAccountDetails')}</span>
          </span>
        </button>
      </div>
      <div className="flex">
        <div className="px-4">
          <div className="bg-hilton-alt flex size-12 items-center justify-center rounded-full p-2">
            <Icon name="user" size="xl" />
          </div>
        </div>
        <div className="brand-ey:font-normal brand-gu:font-normal brand-gu:font-mono brand-hi-refresh:font-normal brand-nd:font-medium brand-nd:font-mono font-bold">
          <span>{t('greetings', { username: user.name })}</span>
          <div className="capitalize">
            <span className="underline">{user.honorsTier}</span> {t('status')}
          </div>
          <div>{t('honorsPointsTotal', { honorsPointsTotal: formattedHonorsPointsTotal })}</div>
          {user.hhonorsNumber ? (
            <div>{t('hiltonHonorsNumber', { hhonorsNumber: user.hhonorsNumber })}</div>
          ) : null}
        </div>
      </div>
      <div className="border-border brand-ey:border-primary-alt brand-ou:border-primary mt-4 divide-y divide-solid border-y">
        {userLinks?.accountLink?.url ? (
          <AccountUserLink
            url={userLinks.accountLink.url}
            onClick={() => {
              trackNavClick('gh_membername_hiltonhonorshome');
            }}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
          >
            {t('hiltonHonorsHome')}
          </AccountUserLink>
        ) : null}
        {userLinks?.honorsProfileLink?.url ? (
          <AccountUserLink
            url={userLinks.honorsProfileLink.url}
            onClick={() => {
              trackNavClick('gh_membername_hiltonhonorsprofile');
            }}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
          >
            {t('profile')}
          </AccountUserLink>
        ) : null}

        {userLinks?.honorsActivityLink?.url && !shouldSuppressHonorsAccountLinks ? (
          <AccountUserLink
            url={userLinks.honorsActivityLink.url}
            onClick={() => {
              trackNavClick('gh_membername_hiltonhonorsactivity');
            }}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
          >
            {t('activity')}
          </AccountUserLink>
        ) : null}

        {userLinks?.honorsPointsLink?.url && !shouldSuppressHonorsAccountLinks ? (
          <AccountUserLink
            url={userLinks.honorsPointsLink.url}
            onClick={() => {
              trackNavClick('gh_membername_hiltonhonorspoints');
            }}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
          >
            {t('pointsLabel')}
          </AccountUserLink>
        ) : null}

        {userLinks?.goHiltonLink?.url ? (
          <AccountUserLink
            url={userLinks.goHiltonLink.url}
            onClick={() => {
              trackNavClick('gh_membername_gohiltonprogramdetails');
            }}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
          >
            {t('goHiltonProgramDetails')}
          </AccountUserLink>
        ) : null}

        {userLinks?.friendsAndFamilyLink?.url ? (
          <AccountUserLink
            url={userLinks.friendsAndFamilyLink.url}
            onClick={() => {
              trackNavClick('gh_membername_managefriendsandfamily');
            }}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
          >
            {t('manageFriendsAndFamily')}
          </AccountUserLink>
        ) : null}

        {userLinks?.hgvMaxLink?.url ? (
          <AccountUserLink
            url={userLinks.hgvMaxLink.url}
            onClick={() => {
              trackNavClick('gh_membername_hgvmaxratedetails');
            }}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
          >
            {t('hgvMaxRateDetails')}
          </AccountUserLink>
        ) : null}

        {userLinks?.smbMemberLink?.url ? (
          <AccountUserLink
            url={userLinks.smbMemberLink.url}
            onClick={() => {
              trackNavClick('gh_membername_managehiltonforbusiness');
            }}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
          >
            {t('manageHiltonForBusiness')}
          </AccountUserLink>
        ) : null}

        <button
          className={cx(
            'text-text border-border block w-full px-6 py-2 text-left font-bold rtl:text-start',
            'brand-ey:font-normal brand-ey:border-primary-alt brand-ey:hover:bg-tertiary-alt brand-ey:focus:bg-tertiary-alt',
            'brand-gu:font-normal brand-gu:font-mono',
            'brand-hi-refresh:font-normal brand-hi-refresh:hover:bg-bg-light brand-hi-refresh:focus:bg-bg-light',
            'brand-lx:hover:bg-bg-light brand-lx:focus:bg-bg-light',
            'brand-nd:font-medium brand-nd:font-mono',
            'brand-ou:border-primary brand-ou:hover:bg-bg-light brand-ou:focus:bg-bg-light'
          )}
          onClick={async () => {
            trackNavClick('gh_membername_signout');
            onClose();
            await onSignOut();
          }}
          type="button"
        >
          {t('signOut')}
        </button>
      </div>
    </FocusLock>
  );
};
