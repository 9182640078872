import { useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import cx from 'classnames';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';

export type DrawerPanelProps = {
  isOpen: boolean;
  children: React.ReactNode;
  alignment: 'left' | 'right';
  /**
   *  Id of the body of your entire app, used to select for all dom elements within app without affecting elements that sit outside the app
   */
  appWrapperId: string;
  /**
   *  Id of the wrapper element you want to keep interactive while the panel is open. Example: Navigation header while nav menu is open
   */
  interactiveElementWrapperId: string;
  panelProps?: React.ComponentProps<'div'>;
  /**
   * Allows you to choose a specific point to return focus to or allow for default returnFocus
   */
  returnFocus?: () => boolean;
};

export const DrawerPanel = ({
  isOpen,
  children,
  alignment,
  appWrapperId,
  interactiveElementWrapperId,
  panelProps,
  returnFocus,
}: DrawerPanelProps) => {
  const [isRightPanelVisible, setIsRightPanelVisible] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      if (alignment === 'right') setIsRightPanelVisible(false);
      document.body.style.overflow = 'auto';
      if (nonInteractiveElements.current)
        nonInteractiveElements.current.forEach((nonInteractiveElement) => {
          if (nonInteractiveElement) {
            nonInteractiveElement.removeAttribute('aria-hidden');
          }
        });
    } else {
      document.body.style.overflow = 'hidden';
      const nonInteractiveElems = document?.querySelectorAll(
        // children of element with interactiveElementWrapperId will not have aria-hidden="true" applied
        `#${appWrapperId} *:not(#${interactiveElementWrapperId}, #${interactiveElementWrapperId} *):not([aria-hidden="true"])`
      );
      nonInteractiveElems.forEach((nonInteractiveElement) => {
        if (nonInteractiveElement) {
          nonInteractiveElement.setAttribute('aria-hidden', 'true');
        }
      });
      /** interactiveElementWrapper should always be available in the accessibility tree,
       *  the selector above will also set aria-hidden on any parents of the interactiveElementWrapper in between it and element with appWrapperId
       * This function removes aria hidden from the drawer's parents */
      let drawerWrapper: Element | null = document?.querySelector(
        `#${interactiveElementWrapperId}`
      );
      const els: Element[] = [];
      while (drawerWrapper) {
        els.push(drawerWrapper);
        drawerWrapper = drawerWrapper.parentElement;
      }
      els?.forEach((navParentElement) => {
        if (navParentElement) {
          navParentElement.removeAttribute('aria-hidden');
        }
      });
      nonInteractiveElements.current = nonInteractiveElems;
    }
  }, [isOpen, alignment, appWrapperId, interactiveElementWrapperId]);

  const nonInteractiveElements = useRef<NodeListOf<Element> | undefined>();

  return (
    <FocusLock disabled={!isOpen} returnFocus={returnFocus ? returnFocus : true}>
      <div
        className={cx(
          'bg-bg border-border brand-ou:bg-secondary absolute top-0 z-50 h-screen w-80 overflow-y-scroll border-t transition-all',
          {
            'ltr:motion-safe:animate-slideInLeft rtl:motion-safe:animate-slideInRight':
              isOpen && alignment === 'left',
            'ltr:motion-safe:animate-slideInRight rtl:motion-safe:animate-slideInLeft':
              isOpen && alignment === 'right',
            'end-0': alignment === 'right',
            'hidden -translate-x-full': !isOpen,
          }
        )}
        onAnimationEnd={() => {
          if (alignment === 'right') setIsRightPanelVisible(true);
        }}
        hidden={!isOpen}
        data-testid={`panelWindow-${alignment}`}
        {...panelProps}
      >
        {/* Right side drawer panel needs to be faded in after animation is completed to avoid focus jump. */}
        {alignment === 'right' ? (
          isRightPanelVisible || (getIsReducedMotion() && isOpen) ? (
            <div className="animate-fadein">{children}</div>
          ) : null
        ) : (
          children
        )}
      </div>
    </FocusLock>
  );
};
