import { baseUrl } from './../../env-vars';

export const assetPath = `${baseUrl}/modules/assets`;
export const localAssetsPath = '/static_hotels_assets';

export const FontPath = `${assetPath}/fonts`;

const svgPath = `${assetPath}/svgs`;

export const getBrandSvg = (brandCode?: string) => `${svgPath}/logos/${brandCode ?? 'WW'}.svg`;
