import type { AgentStatus } from '@dx-ui/framework-conductrics';
import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';

export const useSelectedDatesIndicatorTest = (isShopFormDateViewLoaded: boolean) => {
  const status: AgentStatus = isShopFormDateViewLoaded ? Status.OK : Status.PENDING;
  const { isLoaded, selection } = useConductricsSelection('a-IOSz4RttNuPT', status);
  const isSelectedDatesIndicatorVariant = isLoaded && selection?.choice === 'B';

  return {
    isSelectedDatesIndicatorVariant,
  };
};
