import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { mapCustomClassesToComponent } from '../../helpers/themes/customTheme';

type TLocaleOptions = {
  day?: 'numeric' | '2-digit';
  month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  year: 'numeric' | '2-digit';
  timeZone: string;
};

const createDateString = (date: string, lang: string, isPreSell = false) => {
  const localeOptions: TLocaleOptions = {
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
  };

  if (isPreSell) {
    localeOptions.day = 'numeric';
  }

  return date ? new Date(date).toLocaleDateString(lang, localeOptions) : '';
};

type PreSellOpenAlertProps = {
  brandCode: string;
  isResEnabled: boolean;
  lang: string;
  openDate?: string;
  preOpenMsg?: string;
  resEnabledDate?: string;
};

const PreSellOpenAlert = ({
  brandCode,
  isResEnabled,
  lang,
  openDate,
  preOpenMsg,
  resEnabledDate,
}: PreSellOpenAlertProps) => {
  const { t } = useTranslation('alerts');
  const { isTailored } = useGlobalConfig();
  let messageText: string | null = preOpenMsg || null;
  const wrapperClass = mapCustomClassesToComponent('alertBanner', brandCode, isTailored);

  if (isResEnabled) {
    if (!messageText) {
      const dateString = resEnabledDate ? createDateString(resEnabledDate, lang, true) : null;
      messageText = dateString
        ? t('presellAcceptingWithDate', { dateString })
        : t('presellAcceptingWithoutDate');
    }
  } else if (!isResEnabled) {
    if (!messageText) {
      const dateString = openDate ? createDateString(openDate, lang) : null;
      messageText = dateString
        ? t('presellNotAcceptingWithDate', { dateString })
        : t('presellNotAcceptingWithoutDate');
    }
  }

  return (
    <div data-testid="preSellOpenAlert">
      <span
        className={cx(
          'my-0 flex w-full items-center justify-center text-center leading-6',
          {
            'py-3 px-4 text-text-xs font-extrabold': isTailored,
            'bg-hilton text-text-inverse text-lg md:text-xl p-6 font-semibold': !isTailored,
          },
          wrapperClass
        )}
      >
        {messageText}
      </span>
    </div>
  );
};
export default PreSellOpenAlert;
