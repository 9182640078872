/* @ts strict */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { useSearchParams } from 'next/navigation';
import { addDays, isAfter, isBefore, startOfToday, isEqual as areDatesEqual } from 'date-fns';
import { sendReward, sendRewardAsync } from '@dx-ui/framework-conductrics';
import type { FormDefaultValues, FormDataValues } from '@dx-ui/osc-shop-form';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { logError } from '@dx-ui/framework-logger';
import { digitalData } from '../../metrics/constants';
import { CONDUCTRICS_GOALS } from '../../constants';
import {
  availableSearchParamsMapper,
  formatDateString,
  shapeRoomsToDefaultDataStructure,
} from '../../../components/search/search-helpers';

export type TSearchState = (FormDefaultValues | FormDataValues) & { displayCurrency?: string };

type SearchStateProvider = {
  children: React.ReactNode;
  isGroupSearch?: boolean;
  isResEnabled?: boolean;
  resEnabledDate?: string;
  isOpen?: boolean;
  openDate?: string;
};

const SearchContext = createContext<{
  searchState: TSearchState | null;
  initializeSearchState: (args: TSearchState) => void;
  onSearchChange: (args: TSearchState, options?: { skipStateUpdate?: boolean }) => void;
}>({
  searchState: null,
  initializeSearchState() {
    return undefined;
  },
  onSearchChange() {
    return undefined;
  },
});

export function useSearchContext() {
  return useContext(SearchContext);
}

const SESSION_KEY = 'property_search_state';

export function SearchProvider({
  children,
  isOpen = false,
  isResEnabled = false,
  resEnabledDate = '',
  openDate = '',
  isGroupSearch = false,
}: SearchStateProvider) {
  const hasInitializedSearchStateRef = useRef(false);
  const router = useRouter();
  const searchQueryParams = useSearchParams();
  const { guestInfo, isLoading: isAuthLoading } = useAuth();

  const computedState: TSearchState = useMemo(() => {
    const sessionState = isBrowser ? window.sessionStorage.getItem(SESSION_KEY) : null;
    const initialState: TSearchState = sessionState ? JSON.parse(sessionState) : {};

    const [minArrivalDate, minDepartureDate] = getMinimumStartDays(
      initialState,
      isOpen,
      isResEnabled,
      resEnabledDate,
      openDate,
      isGroupSearch
    );
    return {
      rooms: [],
      ...initialState,
      dates: {
        arrivalDate: minArrivalDate,
        departureDate: minDepartureDate,
        datesFlex: initialState?.dates?.datesFlex,
      },
    };
  }, [isGroupSearch, isOpen, isResEnabled, openDate, resEnabledDate]);

  const [searchState, setSearchState] = useState<TSearchState>(computedState);
  if (isBrowser) {
    const storedState = JSON.parse(window.sessionStorage.getItem(SESSION_KEY) || '{}');
    if (!isEqual(searchState, storedState)) {
      window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(searchState));
    }
  }

  const syncUrl = useCallback(
    async (nextState: TSearchState) => {
      const [url, search] = router.asPath?.split('?');
      const searchParams = new URLSearchParams(search);
      searchParams.delete('hotelSlug');
      if (searchParams.has('smbRate')) {
        searchParams.set('smbRate', String(Boolean(nextState?.specialRates?.smbRate)));
        await router.replace({ pathname: url, query: searchParams.toString() }, undefined, {
          shallow: true,
        });
      }
    },
    [router]
  );

  const onSearchChange = useCallback(
    async (results: TSearchState, { skipStateUpdate }: { skipStateUpdate?: boolean } = {}) => {
      if (results && !isEqual(results, searchState)) {
        if (isBrowser) {
          window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(results));
        }
        if (results?.dates?.datesFlex) {
          sendReward(CONDUCTRICS_GOALS.FLEXIBLE_DATES_CTA);
        }
        const previousRoomCount = searchState?.rooms?.length || 0;
        const newRoomCount = results?.rooms?.length || 0;
        if (previousRoomCount !== newRoomCount && newRoomCount > 1) {
          sendReward('edit-property-search-to-multi-room');
        }
        if (results.dates?.departureDate && !skipStateUpdate) {
          const prevDepartureDate = searchState?.dates?.departureDate;
          if (prevDepartureDate && !areDatesEqual(results.dates.departureDate, prevDepartureDate)) {
            sendReward(CONDUCTRICS_GOALS.EDIT_CALENDAR_DATES);
          }
          await syncUrl(results);
          setSearchState(results);
        }
        await sendRewardAsync(CONDUCTRICS_GOALS.EDIT_PROPERTY_SEARCH_WIDGET);
        if (
          !(digitalData.conductrics_traits as string[]).includes(
            'edit-property-widget:edited-widget'
          )
        ) {
          (digitalData.conductrics_traits as string[]).push('edit-property-widget:edited-widget');
        }
        if (digitalData.page?.pageInfo?.pageType === 'Rooms') {
          await sendRewardAsync(CONDUCTRICS_GOALS.EDIT_PROPERTY_ROOMS_PAGE_SEARCH_WIDGET);
        }
      }
    },
    [searchState, syncUrl]
  );

  const initializeSearchState = useCallback(
    (formState: TSearchState) => {
      if (!hasInitializedSearchStateRef.current) {
        hasInitializedSearchStateRef.current = true;

        const initialSearchQueryParams = availableSearchParamsMapper(searchQueryParams);

        const searchStateFromParamsRooms = shapeRoomsToDefaultDataStructure(
          initialSearchQueryParams.rooms,
          initialSearchQueryParams.ages
        ).filter((room) => !!room.adults);

        const searchStateFromParams: FormDefaultValues = {
          ...initialSearchQueryParams,
          rooms: searchStateFromParamsRooms.length ? searchStateFromParamsRooms : undefined,
        };

        const mergedSpecialRates = merge(
          formState?.specialRates,
          searchStateFromParams.specialRates
        );

        const mergedSearchState: TSearchState = {
          ...merge(formState, searchStateFromParams),
          specialRates: {
            ...mergedSpecialRates,
          },
          rooms: merge(formState?.rooms, searchStateFromParams.rooms) ?? [],
          dates: {
            arrivalDate: formState?.dates?.arrivalDate ?? searchStateFromParams?.dates?.arrivalDate,
            departureDate:
              formState?.dates?.departureDate ?? searchStateFromParams?.dates?.departureDate,
            datesFlex: formState?.dates?.datesFlex ?? searchStateFromParams?.dates?.datesFlex,
          },
        };
        setSearchState(mergedSearchState);
      }
    },
    [searchQueryParams]
  );

  useEffect(() => {
    async function syncState() {
      const isSMBMember = guestInfo?.hhonors?.isSMBMember;
      const searchParams = new URLSearchParams(window.location.search);
      const hasSmbRateParam = searchParams.has('smbRate');
      const smbRateValue = searchParams.get('smbRate') || '';
      const shopWithSmbRate = Boolean(/1|true/.test(smbRateValue));
      const searchStateSMBRate = searchState?.specialRates?.smbRate ?? false;

      if (!isAuthLoading && hasSmbRateParam) {
        if (shopWithSmbRate !== searchStateSMBRate || (shopWithSmbRate && !isSMBMember)) {
          const newState = {
            ...searchState,
            specialRates: {
              ...searchState?.specialRates,
              smbRate: Boolean(isSMBMember && shopWithSmbRate),
            },
          };
          await syncUrl(newState);
          setSearchState(newState);
        }
      }
    }

    syncState().catch((error: string | Error | Record<string, unknown>) =>
      logError('SEARCH_PROVIDER', error, 'failed to run syncState()')
    );
  }, [guestInfo?.hhonors?.isSMBMember, isAuthLoading, searchState, syncUrl]);

  return (
    <SearchContext.Provider value={{ searchState, onSearchChange, initializeSearchState }}>
      {children}
    </SearchContext.Provider>
  );
}

function getMinimumStartDays(
  initialState: TSearchState,
  isOpen: boolean,
  isResEnabled: boolean,
  resEnabledDate: string,
  openDate: string,
  isGroupSearch: boolean
) {
  const getStartDate = () => {
    if (!isOpen && ((isResEnabled && resEnabledDate) || openDate)) {
      return formatDateString(isResEnabled ? resEnabledDate : openDate);
    }
    return new Date(Date.now());
  };

  let startDay: Date = getStartDate();
  startDay = isAfter(startDay, startOfToday()) ? startDay : startOfToday();
  let searchStateArrivalDate = initialState?.dates?.arrivalDate ?? startDay;
  searchStateArrivalDate = isBefore(searchStateArrivalDate, startDay)
    ? startDay
    : searchStateArrivalDate;
  if (isGroupSearch && isBefore(searchStateArrivalDate, addDays(startDay, 7))) {
    searchStateArrivalDate = addDays(startDay, 7);
  }
  const arrivalDate = searchStateArrivalDate;

  const minDepartureDate = addDays(arrivalDate, isGroupSearch ? 2 : 1);
  const searchStateDepartureDate = initialState?.dates?.departureDate ?? addDays(startDay, 1);
  const departureDate = isAfter(searchStateDepartureDate, minDepartureDate)
    ? searchStateDepartureDate
    : minDepartureDate;

  return [arrivalDate, departureDate];
}
