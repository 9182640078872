import cx from 'classnames';
import type * as React from 'react';

// todo: move these into other components
export function Pill({ children, className, ...rest }: React.ComponentProps<'span'>) {
  return (
    <span
      className={cx(
        className,
        'inline-block rounded-full bg-bg-disabled px-3 py-2 text-xs font-bold'
      )}
      {...rest}
    >
      {children}
    </span>
  );
}
