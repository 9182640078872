import { LoginIFrame } from '@dx-ui/osc-login';
import type { LoginResponse, LoginBaseProps } from '@dx-ui/osc-login';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import * as React from 'react';
import { SpeechBubble } from './speech-bubble';
import { sendInteractionReward } from '../../header.utilities';
import { sendReward } from '@dx-ui/framework-conductrics';

type NavItemWithSpeechBubbleProps = {
  title: LoginBaseProps['title'];
  frameSrc: LoginBaseProps['frameSrc'];
  options?: LoginBaseProps['options'];
  onLoginAttempt: React.ComponentProps<typeof LoginIFrame>['onLoginAttempt'];
  variant: string;
  buttonClassName: LoginBaseProps['buttonClassName'];
  onClick?: () => void;
  onDialogOpen?: () => void;
};

export const NavItemWithSpeechBubble = ({
  onLoginAttempt,
  title,
  options = {},
  frameSrc,
  variant,
  buttonClassName,
  onClick,
  onDialogOpen,
  children,
}: React.PropsWithChildren<NavItemWithSpeechBubbleProps>) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [showSpeechBubble, setShowSpeechBubble] = React.useState(true);

  const [speechBubbleContainer, setSpeechBubbleContainer] = React.useState<
    HTMLDivElement | undefined
  >(undefined);

  const buttonRef = React.useRef<React.ElementRef<'button'>>(null);

  const onSignInClick = () => {
    onClick?.();
    onDialogOpen?.();
    setShowDialog(true);
    setShowSpeechBubble(false);
    sendInteractionReward();
    sendReward('nav-sign-in-click');
  };

  const close = () => setShowDialog(false);

  const closeSpeechBubble = () => {
    setShowSpeechBubble(false);
    buttonRef?.current?.focus();
  };

  const setRef = (node: HTMLDivElement) => {
    if (node) {
      setSpeechBubbleContainer(node);
    }
  };

  const handleLogin = async (response: LoginResponse) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await onLoginAttempt?.(response);
    if (!response.error) {
      close();
    }
  };
  return (
    <>
      <Dialog
        title={title}
        isOpen={showDialog}
        onDismiss={close}
        size="md"
        dialogTrigger={
          <SpeechBubble
            closeSpeechBubble={closeSpeechBubble}
            showSpeechBubble={showSpeechBubble}
            variant={variant}
            containerRef={speechBubbleContainer}
            onInlineButtonClick={() => {
              closeSpeechBubble?.();
              setShowDialog(true);
              onDialogOpen?.();
            }}
          >
            <button
              data-testid="with-modal"
              type="button"
              className={buttonClassName}
              onClick={onSignInClick}
              ref={buttonRef}
              aria-describedby="sign-in-note"
            >
              {children}
            </button>
          </SpeechBubble>
        }
      >
        <LoginIFrame
          frameSrc={frameSrc}
          options={options}
          data-e2e="loginIframe"
          onLoginAttempt={handleLogin}
          onClose={close}
          title={title}
        />
      </Dialog>
      <div ref={setRef} />
    </>
  );
};

export default NavItemWithSpeechBubble;
