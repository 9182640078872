import type { MutableRefObject } from 'react';
import { forwardRef, memo } from 'react';
import cx from 'classnames';
import { HotelAddress } from '@dx-ui/osc-hotel-address';
import { useInitializeConductrics } from '../../hooks/use-initialize-conductrics';
import HotelChip from '../layout/HotelChip';
import SearchWidget from './SearchWidget';
import PreSellOpenAlert from '../layout/PreSellOpenAlert';
import type { TSearchWidgetContentProps } from './search.types';

const SearchWidgetContent = forwardRef<HTMLDivElement, TSearchWidgetContentProps>(
  (
    {
      resEnabled,
      open,
      openDate,
      preOpenMsg,
      resEnabledDate,
      hotelName,
      address,
      isFromWiFi,
      isNewHotel,
      traits = [],
      brandCode,
      ctyhocn,
      lang,
      templateType,
      shouldDisplayShopFormOnPage,
      searchWidgetProps,
      isRecentlyRenovated,
    },
    ref
  ) => {
    const shopFormRef = ref as MutableRefObject<HTMLInputElement>;

    const {
      adultAge,
      ageBasedPricing,
      adultsOnly,
      isPartnerBrand,
      showNumAttendees,
      maxNumRooms,
      maxOccupants,
      isGroupSearch,
      hideFlexDates,
      minArrivalDate,
      maxArrivalDate,
      currencyCode,
    } = searchWidgetProps;

    useInitializeConductrics({
      traits: [
        `template:${templateType}`,
        `Brand:${brandCode}`,
        `newHotel:${isNewHotel ? 'yes' : 'no'}`,
        `wifi-visit-inspired:${isFromWiFi ? 'yes' : 'no'}`,
        ...traits,
      ],
    });

    return (
      <>
        {!open ? (
          <PreSellOpenAlert
            brandCode={brandCode}
            isResEnabled={resEnabled ?? false}
            lang={lang}
            openDate={openDate ?? ''}
            preOpenMsg={preOpenMsg ?? ''}
            resEnabledDate={resEnabledDate ?? ''}
          />
        ) : null}

        <div className="container flex flex-col items-center justify-between text-pretty xl:flex-row">
          {hotelName ? (
            <div className="flex w-full flex-col items-center justify-center text-center xl:me-4 xl:items-start xl:justify-start xl:text-start">
              <div className="text-balance">
                <h1
                  className={cx('heading-xl inline', {
                    'lg:heading-xl': hotelName.length >= 60,
                    'lg:heading-2xl': hotelName.length < 60,
                  })}
                >
                  {hotelName}
                </h1>
                {isNewHotel || isRecentlyRenovated ? (
                  <HotelChip
                    className="ms-1 align-[0.25cap] lg:align-[0.5cap]"
                    variant={isNewHotel ? 'new' : 'renovated'}
                  />
                ) : null}
              </div>
              {address ? (
                <div className="pt-3.5 text-center xl:text-balance xl:pt-1 xl:text-start">
                  <HotelAddress
                    hasLink={true}
                    addressLine1={address?.addressLine1 || ''}
                    city={address?.mapCity || ''}
                    countryCode={address?.country || ''}
                    hotelName={hotelName || ''}
                    stateCode={address?.state}
                    singleLineAddress={address.addressFmt || ''}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {resEnabled && shouldDisplayShopFormOnPage ? (
            <div
              ref={shopFormRef}
              className="py-6 transition-all duration-[0.8s] ease-in-out motion-reduce:transition-none lg:pb-0 lg:pt-6 lg:transition-none xl:mt-0 xl:pt-0"
              data-testid="search-widget-container"
            >
              <SearchWidget
                currencyCode={currencyCode || ''}
                adultAge={adultAge}
                ageBasedPricing={ageBasedPricing}
                brandCode={brandCode}
                ctyhocn={ctyhocn}
                isAdultsOnly={adultsOnly}
                isGroupSearch={isGroupSearch}
                isPartnerBrand={isPartnerBrand}
                showNumAttendees={showNumAttendees ?? false}
                maxNumRooms={maxNumRooms}
                maxOccupants={maxOccupants}
                hideFlexDates={hideFlexDates}
                minArrivalDate={minArrivalDate}
                maxArrivalDate={maxArrivalDate}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  }
);

SearchWidgetContent.displayName = 'SearchWidgetContent';
export default memo(SearchWidgetContent);
