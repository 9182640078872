import { BannerNotification } from '@dx-ui/osc-banner-notification';
import { FormErrorIcon, FormInput, FormSelect } from '@dx-ui/osc-form';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import type { FieldError, SubmitErrorHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import type { ReactNode } from 'react';

import { AddressFields } from './address-fields';
import { FormInputPassword } from './password-fields';
import { FormInputPhone } from './phone-fields';
import { MarketingContent as MarketingContentCCS } from './marketing-content-css';
import { MarketingContent } from './marketing-content';

import {
  CreateGuestDocument,
  useBrand_CountriesQuery,
  useCreateGuestMutation,
  useLanguages_DefaultCountryCodeQuery,
} from './queries/generated/queries';

import { AutoComplete } from './auto-complete';
import type { CreateGuestMutation, CreateGuestMutationVariables } from './queries/generated/types';
import { email } from './form-validation-regex';
import { Spinner } from '@dx-ui/osc-spinner';
import type { PageInfo } from '@dx-ui/config-metrics';
import type { JoinFormMetrics } from './types/join-form-analytics';
import { sendErrorAnalytics } from './utils/join-form-analytics';
import { logError } from '@dx-ui/framework-logger';
import { generateError } from '@dx-ui/utilities-generate-error';
import unset from 'lodash/unset';
import type { DXError } from '@dx-ui/types-graphql';

type BannerContent = { message: string; type: 'success' | 'error' } | null;

const GRAPH_ERRORS = {
  WANTS_TOTP: 996,
  TOTP_REMOVED: 995,
  COMMUNICATIONS_ERROR: 1002,
  RESERVATION_LOGIN_ERROR: 22,
  RESERVATIONWITH24HRS_LOGIN_ERROR: 16,
  CONFLICT_WITH_RESOURCE: 409,
  SUCCESSFULL_REGISRATION_DELAYED_CONFIRMATION: 1000,
} as const;

const EmailSubscription = {
  PERSONALIZED_OFFERS: 'SGMT',
  HILTON_MARKETING: 'HHPRM',
  GRAND_VACATIONS: 'HGVC',
  HHONORS_EMAIL: 'CUST',
  THIRD_PARTY_MARKETING: '3PROM',
} as const;

const USMarketingOptIn = {
  hhonorsSubscriptions: [
    EmailSubscription.HHONORS_EMAIL,
    EmailSubscription.HILTON_MARKETING,
    EmailSubscription.PERSONALIZED_OFFERS,
    EmailSubscription.GRAND_VACATIONS,
  ],
  optOuts: {},
};

type PageInfoKeys = keyof PageInfo;

const FORM_ERRORS_TO_FILTER = ['alertBoxText', 'formError', 'formErrorMessage'] as PageInfoKeys[];

const oCodes = [
  'ADAPW',
  'ANDEW',
  'BRDEW',
  'CIHCW',
  'CNMP',
  'DSKEW',
  'DTITW',
  'ESHCW',
  'HHHTW',
  'HHPCW',
  'HITEW',
  'HPHCW',
  'IOSEW',
  'JNHHW',
  'NHBHW',
  'NMUPW',
  'OHWBW',
  'OHWE',
  'OHWGW',
  'PYHTW',
  'QQHTW',
  'RUHCW',
  'WAHCW',
  'WFPAW',
  'JHTNW',
  'ASPEW',
  'SMBEW',
  'PEPEW',
];

export type JoinInput = Omit<
  CreateGuestMutationVariables['input'],
  'username, propCode, country'
> & {
  country: string;
  marketingOffers: boolean;
  marketingConsent?: boolean;
  marketingGrandVacations?: boolean;
};

export interface Props {
  /*
   * Feature toggle on guest, disabled by default
   */
  isMarketabilityCCSEnabled?: boolean;
  language: string;
  /**
   * Enrollment OCode, required
   */
  oCode: string;
  /**
   * Called after successful enrollment
   */
  onSuccess: (enrollResult: CreateGuestMutation) => void;
  /*
   * Metrics object, at a minimum needed these functions
   */
  metrics: JoinFormMetrics;

  headline: ReactNode;
  origin: string;
}

export function JoinForm({
  isMarketabilityCCSEnabled = false,
  language,
  oCode,
  onSuccess,
  metrics,
  headline,
  origin,
}: Props) {
  const [t] = useTranslation('osc-join-form-join');
  const { t: tForm } = useTranslation('osc-join-form-form');
  const [bannerContent, setBannerContent] = useState<BannerContent>(null);
  const [marketingOptinsSelected, setMarketingOptinsSelected] = useState<number[]>([]);
  const [isMarketingOffersModalOpen, setIsMarketingOffersModalOpen] = useState(false);
  const { data: defaultCountryCodeData } = useLanguages_DefaultCountryCodeQuery({
    language,
  });

  const isJapanesePage = language === 'ja';

  const form = useForm<JoinInput>({
    mode: 'onTouched',
    criteriaMode: 'all',
    defaultValues: {
      address: {},
      country: defaultCountryCodeData?.languages?.[0]?.defaultCountryCode || 'US',
      phone: {
        phoneCountry: `1US`,
      },
    },
  });

  const { watch, formState, setValue, handleSubmit } = form;
  const { errors, isSubmitted } = formState;

  const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    if (defaultCountryCodeData?.languages?.[0]?.defaultCountryCode) {
      setValue('country', defaultCountryCodeData?.languages?.[0]?.defaultCountryCode);
    }
    if (isJapanesePage) {
      setValue('phone.phoneCountry', '81JP');
    }
  }, [defaultCountryCodeData, isJapanesePage, setValue]);

  const marketingOffersValue = watch('marketingOffers');
  useEffect(() => {
    if (marketingOffersValue) {
      return setIsMarketingOffersModalOpen(true);
    }
  }, [marketingOffersValue]);

  const hasPasswordFields = oCodes?.includes(oCode);

  const [loadingGeocode, setLoadingGeocode] = useState(false);

  const { data, isLoading: isLoadingCountries } = useBrand_CountriesQuery({
    language,
    getMarketing: true,
    getCCSMarketing: isMarketabilityCCSEnabled,
    countryFilter: { prohibited: false },
  });

  const createGuest = useCreateGuestMutation();

  const countries =
    [...(data?.countries || [])].sort((a, b) => Intl.Collator(language).compare(a.name, b.name)) ||
    [];

  const selectedCountry = countries.find(({ code }) => code === watch('country'))!;
  const marketingOptIns = selectedCountry?.marketingOptin?.marketingOptinRules?.[0]?.optIns;

  const isChina = selectedCountry?.code === 'CN';
  const isUSA = selectedCountry?.code === 'US';

  const getErrors = () => {
    const elements: ReactNode[] = [];
    Object.entries(errors).forEach(([key, value]) => {
      if (value.message) {
        const message = value.message;
        elements.push(<li key={`joinPageErrorMsg${key}`}>{message}</li>);
      }

      // need to go in one level deeper
      Object.entries(value).forEach(([innerKey, innerValue]) => {
        if ((innerValue as FieldError).message) {
          const message = (innerValue as FieldError).message;
          elements.push(<li key={`joinPageErrorMsg${innerKey}`}>{message}</li>);
        }
      });
    });
    return elements;
  };

  const updateCreateGuestMutation = ({
    address,
    email,
    name,
    password,
    phone,
    country,
    marketingGrandVacations,
    marketingOffers,
    marketingConsent,
  }: JoinInput) => {
    let hhonorsSubscriptions: string[] = [];
    if (isMarketabilityCCSEnabled) {
      const marketgOptinsAutoEnrolled = marketingOptIns?.filter((optin) => optin?.autoOptIn);
      if (marketgOptinsAutoEnrolled && marketgOptinsAutoEnrolled.length) {
        hhonorsSubscriptions = marketgOptinsAutoEnrolled.flatMap(
          (optin) => optin.subscriptionCodes
        );
      }
      if (marketingOptinsSelected.length && marketingOptIns && marketingOptIns.length) {
        marketingOptinsSelected.forEach((i) => {
          const codes = marketingOptIns[i]?.subscriptionCodes || [];
          const list = [...hhonorsSubscriptions, ...codes];
          hhonorsSubscriptions = [...list];
        });
      }
    } else {
      const isGreenMarketingOptIn = selectedCountry?.marketingOptIn === 'greenMarketingOptIn';
      if (isGreenMarketingOptIn) {
        hhonorsSubscriptions = [
          ...hhonorsSubscriptions,
          EmailSubscription.PERSONALIZED_OFFERS,
          EmailSubscription.HILTON_MARKETING,
        ];
        if (selectedCountry?.marketingOptInForHGV) {
          marketingGrandVacations && hhonorsSubscriptions.push(EmailSubscription.GRAND_VACATIONS);
        } else {
          hhonorsSubscriptions.push(EmailSubscription.GRAND_VACATIONS);
        }
      } else {
        marketingOffers && hhonorsSubscriptions.push(EmailSubscription.PERSONALIZED_OFFERS);
        marketingConsent && hhonorsSubscriptions.push(EmailSubscription.HILTON_MARKETING);
        marketingGrandVacations && hhonorsSubscriptions.push(EmailSubscription.GRAND_VACATIONS);
      }
      hhonorsSubscriptions.push(EmailSubscription.HHONORS_EMAIL);
    }
    address.country = country;
    const input: CreateGuestMutationVariables['input'] = {
      address: {
        ...address,
        ...(address.state !== 'address' && { state: address.state }),
        addressType: 'home',
      },
      email,
      enrollSourceCode: oCode,
      name,
      password,
      phone: {
        phoneNumber: phone?.phoneNumber || '',
        phoneCountry: phone?.phoneCountry?.replace(/\D+/, ''),
        phoneType: 'home',
      },
      preferredLanguage: language === 'id' ? 'en' : language,
      privacyRequested: false,
      subscriptions:
        !isMarketabilityCCSEnabled && isUSA
          ? USMarketingOptIn
          : { hhonorsSubscriptions, optOuts: {} },
    };

    return new Promise((_resolve) => {
      createGuest?.mutate([CreateGuestDocument, { language, input }], {
        onSuccess: (data: CreateGuestMutation) => {
          const errorCode = data?.createGuest?.error?.code;
          if (errorCode) {
            switch (errorCode) {
              case GRAPH_ERRORS.SUCCESSFULL_REGISRATION_DELAYED_CONFIRMATION:
              case GRAPH_ERRORS.CONFLICT_WITH_RESOURCE:
                return setBannerContent({
                  message: t(`submit_errors.${errorCode}`),
                  type: 'error',
                });
              case 997:
                if (
                  isJapanesePage &&
                  data?.createGuest?.error?.notifications?.some(({ code }) => code === 512)
                ) {
                  return setBannerContent({
                    message: t(`submit_errors.997`),
                    type: 'error',
                  });
                }
                return setBannerContent({
                  message: t(`submit_errors.500`),
                  type: 'error',
                });
              default:
                return setBannerContent({
                  message: t(`submit_errors.500`),
                  type: 'error',
                });
            }
          }

          const hHonorsNumber = data?.createGuest?.data?.hhonorsNumber;
          const encodedName = window.btoa(encodeURIComponent(name.firstName));
          const encodedNum = window.btoa(hHonorsNumber || '');
          const encodedEmail = window.btoa(encodeURIComponent(email.emailAddress));
          const encodedEnroll = window.btoa('true');

          sessionStorage.setItem('first_name', encodedName);
          sessionStorage.setItem('hhonors', encodedNum);
          sessionStorage.setItem('email', encodedEmail);
          sessionStorage.setItem('firstEnroll', encodedEnroll);
          onSuccess(data);
        },
        onError: (data) => {
          setBannerContent({
            message: t('submit_errors.500'),
            type: 'error',
          });
          logError('JOIN_FORM', generateError(data));
        },
      });
    });
  };

  const handleFormSubmit = (formData: JoinInput) => {
    if (window.digitalData?.page?.pageInfo) {
      FORM_ERRORS_TO_FILTER.forEach((key) => unset(window?.digitalData?.page?.pageInfo, key));
    }
    updateCreateGuestMutation(formData)
      .then((res) => res)
      .catch((error: DXError) => {
        throw error;
      });
  };

  const handleInvalidForm: SubmitErrorHandler<JoinInput> = (fieldErrors) => {
    // hasErrors above reads errors before validation is completed, missing new errors on submit.
    // Using onInvalid ensures we get the final, updated errors.
    const hasFieldErrors = Object.keys(fieldErrors).length > 0;
    if (hasFieldErrors) {
      const formErrors = Object.keys(errors).flatMap((key) => key);
      const formErrorsMessages = (Object.keys(errors) as (keyof typeof errors)[])
        .flatMap((key) => errors[key]?.message)
        .filter((val): val is string => Boolean(val));

      sendErrorAnalytics(metrics, formErrors, formErrorsMessages);
    }
  };

  return (
    <div className="container">
      {bannerContent ? (
        <BannerNotification status={bannerContent?.type}>
          <p className="font-bold sm:text-lg">{bannerContent?.message}</p>
        </BannerNotification>
      ) : null}
      <div className="mx-auto my-10 max-w-xl">
        {headline}
        {hasErrors && isSubmitted && (
          <div
            role="alert"
            data-testid="form-errors"
            className={cx('bg-danger-alt text-danger w-full', {
              'mb-4 p-4': hasErrors && isSubmitted,
            })}
          >
            <h2 className="text-danger flex items-center space-x-2 font-semibold rtl:space-x-reverse">
              <FormErrorIcon />
              <span>{t('form_error')}</span>
            </h2>
            <ul className="text-danger ml-10 rtl:space-x-reverse">{getErrors()}</ul>
          </div>
        )}
        <FormProvider {...form}>
          <form
            noValidate
            method="post"
            onSubmit={handleSubmit(handleFormSubmit, handleInvalidForm)}
            className="flex w-full flex-col space-y-4"
          >
            <p>{tForm('requiredFields')}</p>
            {isJapanesePage ? <p>{tForm('enterHiraganaCharacters')}</p> : null}
            <FormInput
              name="name.firstName"
              autoComplete={AutoComplete.GIVENNAME}
              label={tForm('firstName.label')}
              labelClassName="w-full"
              optional={false}
              maxLength={18}
              registerOptions={{
                required: { value: true, message: tForm('firstName.error') },
                minLength: { value: 1, message: tForm('firstName.error_minLength') },
                pattern: { value: /^\D+$/, message: tForm('firstName.error_noDigits') },
              }}
            />
            <FormInput
              name="name.lastName"
              autoComplete={AutoComplete.FAMILYNAME}
              label={tForm('lastName.label')}
              labelClassName="w-full"
              optional={false}
              maxLength={18}
              registerOptions={{
                required: { value: true, message: tForm('lastName.error') },
                minLength: { value: 1, message: tForm('lastName.error_minLength') },
                pattern: { value: /^\D+$/, message: tForm('lastName.error_noDigits') },
              }}
            />

            <FormInputPhone
              options={countries}
              defaultValue="1US"
              loading={isLoadingCountries}
              labelClassName="w-full"
            />

            <FormInput
              name="email.emailAddress"
              autoComplete={AutoComplete.EMAIL}
              label={tForm('email.label')}
              type="email"
              labelClassName="w-full"
              optional={false}
              maxLength={64}
              registerOptions={{
                required: { value: true, message: tForm('email.error') },
                pattern: { value: email, message: tForm('email.error') },
              }}
            />

            <FormSelect
              autoComplete={AutoComplete.COUNTRY}
              labelClassName="w-full"
              label={tForm('address.country.label')}
              name="country"
              loading={isLoadingCountries}
              disabled={isLoadingCountries}
            >
              {countries.map(({ name, code }) => (
                <option
                  key={`option-${code}`}
                  value={code}
                  {...(code === watch('country') ? { selected: true } : {})}
                >
                  {name}
                </option>
              ))}
            </FormSelect>

            <AddressFields
              code={watch('country')}
              groupName="address"
              setLoadingGeocode={setLoadingGeocode}
            />

            {hasPasswordFields && (
              <>
                <FormInputPassword
                  autoComplete={AutoComplete.NEWPASSWORD}
                  type="password"
                  name="password"
                  label={tForm('password.label')}
                  labelClassName="w-full"
                />
                <FormInput
                  autoComplete={AutoComplete.NEWPASSWORD}
                  type="password"
                  name="confirmPassword"
                  label={tForm('confirmPassword.label')}
                  labelClassName="w-full"
                  optional={false}
                  containerClassName="!mb-10"
                  registerOptions={{
                    validate: {
                      shouldMatch: (value) =>
                        (!!value && value === watch('password')) || tForm('confirmPassword.error'),
                    },
                  }}
                />
              </>
            )}
            <div className="space-y-10">
              {isMarketabilityCCSEnabled ? (
                <MarketingContentCCS
                  selectedCountry={selectedCountry}
                  setMarketingOptinsSelected={setMarketingOptinsSelected}
                  language={language}
                  origin={origin}
                />
              ) : (
                <MarketingContent
                  isUSA={isUSA}
                  isChina={isChina}
                  selectedCountry={selectedCountry}
                  isMarketingOffersModalOpen={isMarketingOffersModalOpen}
                  setIsMarketingOffersModalOpen={setIsMarketingOffersModalOpen}
                  language={language}
                  origin={origin}
                />
              )}

              <button
                type="submit"
                className="btn btn-2xl btn-primary w-full"
                disabled={loadingGeocode || createGuest.isPending}
              >
                {createGuest.isPending ? (
                  <>
                    {tForm('submitting')} <Spinner className="ml-2 inline" size="sm" />
                  </>
                ) : (
                  t('submit', { context: null })
                )}
              </button>

              <p className="text-xs">
                <Trans t={t} i18nKey="termsAndConditions_disclaimer">
                  <Link
                    url={`/${language}/hilton-honors/honors-discount-terms/`}
                    className="text-primary hover:text-primary-alt text-xs"
                    isNewWindow
                  />
                </Trans>
              </p>
              <p className="!mt-6 text-xs">{t('wiFi_disclaimer')}</p>
              {(selectedCountry?.marketingConsent || selectedCountry?.marketingOptIn) && (
                <>
                  <p className="text-text-alt text-xs">{t('gdprFootnote')}</p>
                  <p className="text-text-alt !mt-6 text-xs">{t('gdprFootnote2')}</p>
                </>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
