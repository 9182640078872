import type { Agent, ConductricsSelection } from '@dx-ui/framework-conductrics';
import { useConductricsSelection, useShouldRenderExperiment } from '@dx-ui/framework-conductrics';
import { filterMegaNavExperiments } from '../util/filter-mega-nav-experiments';
import type { TDrawerItem } from '../types';

/**
 * Filters mega nav links by Conductrics agent(s).
 * Conductrics agents are automatically fetched by this hook if links have an agent defined.
 */
export function useFilterMegaNavExperiments(megaNavLinks: TDrawerItem[] | undefined) {
  const agents = findAgentsToFetch(megaNavLinks);
  const selections = useConsistentConductricsSelection(agents);
  const shouldRenderExperiment = useShouldRenderExperiment();
  return filterMegaNavExperiments(megaNavLinks, selections, shouldRenderExperiment);
}

/**
 * Loop over navigation drawer items to find agent IDs that need to be fetched via Conductrics
 */
function findAgentsToFetch(megaNavLinks?: TDrawerItem[]): Agent[] {
  if (!megaNavLinks) {
    return [];
  }

  const agents = new Set<string>();

  for (const item of megaNavLinks) {
    if (item.link?.experimentationConfiguration?.agentId) {
      agents.add(item.link.experimentationConfiguration.agentId);
    }

    if (Array.isArray(item.subMenu)) {
      for (const subMenuItem of item.subMenu) {
        if (subMenuItem.link?.experimentationConfiguration?.agentId) {
          agents.add(subMenuItem.link.experimentationConfiguration.agentId);
        }
      }
    }
  }

  return Array.from(agents).map((agentId) => ({ agentId }));
}

/**
 * Always returns a Record<string, ConductricsSelection>
 *
 * useConductricsSelection will return an object that is of type `ConductricsSelection` instead of
 * Record<AgentID, ConductricsSelection> when there is an array with a single agent ID passed to it.
 * We need to convert this to a record for the filter function.
 */
function useConsistentConductricsSelection(agents: Agent[]) {
  const isSingleAgent = agents.length === 1;
  const selections = useConductricsSelection(agents);

  if (isSingleAgent && isConductricsSingleSelection(selections)) {
    const agentId = agents?.[0]?.agentId;

    return {
      [`${agentId}`]: selections,
    };
  }

  return selections;
}

function isConductricsSingleSelection(value: unknown): value is ConductricsSelection {
  return (
    typeof value === 'object' &&
    value !== null &&
    'isLoaded' in value &&
    'error' in value &&
    'selection' in value
  );
}
